import { combineReducers, Dispatch } from 'redux';
import { TypedUseSelectorHook, useDispatch as useDispatchNative, useSelector as useSelectorNative } from 'react-redux';
import { AggregateRootState } from '@scout24ch/fs24-utils';
import { actionCreators as loadingAC, reducer as loading } from './loading';
import { actionCreators as languageAC, reducer as language } from './language';
import { actionCreators as inquiryAC, reducer as inquiry } from './inquiry';
import {
  actionCreators as privateLiabilityInquiryAC,
  reducer as privateLiabilityInquiry,
} from './privateLiabilityInquiry';
import {
  actionCreators as privateLiabilityPremiumsAC,
  reducer as privateLiabilityPremiums,
} from './privateLiabilityPremiums';
import {
  actionCreators as selectedPrivateLiabilityPremiumsAC,
  reducer as selectedPrivateLiabilityPremiums,
} from './selectedPrivateLiabilityPremiums';
import { actionCreators as offerAC, reducer as offer } from './offer';
import { actionCreators as premiumsAC, reducer as premiums } from './premiums';
import { actionCreators as requestedOffersAC, reducer as requestedOffers } from './requestedOffers';
import { actionCreators as selectedPremiumsAC, reducer as selectedPremiums } from './selectedPremiums';
import { actionCreators as inquiryErrorAC, reducer as inquiryError } from './inquiryError';
import {
  actionCreators as privateLiabilityInquiryErrorAC,
  reducer as privateLiabilityInquiryError,
} from './privateLiabilityError';
import { actionCreators as resultSortingOrderAC, reducer as resultSortingOrder } from './resultSortingOrder';
import { actionCreators as alertsAC, reducer as alert } from './alerts';
import { actionCreators as featureFlagsAC, reducer as featureFlags } from './featureFlags';

import { actionCreators as mobiliarInquiryAC, reducer as mobiliarInquiry } from './mobiliar/inquiry';
import { actionCreators as mobiliarInquiryErrorAC, reducer as mobiliarInquiryError } from './mobiliar/inquiryError';
import { actionCreators as mobiliarPremiumsAC, reducer as mobiliarPremiums } from './mobiliar/premiums';
import {
  actionCreators as mobiliarSelectedPremiumsAC,
  reducer as mobiliarSelectedPremiums,
} from './mobiliar/selectedPremiums';
import { actionCreators as mobiliarOfferAC, reducer as mobiliarOffer } from './mobiliar/offer';
import {
  actionCreators as mobiliarRequestedOffersAC,
  reducer as mobiliarRequestedOffers,
} from './mobiliar/requestedOffers';

// map of all reducers to root-state, you only need to add your new one here
const reducers = {
  loading,
  language,
  resultSortingOrder,
  featureFlags,
  alert,

  inquiry,
  inquiryError,
  premiums,
  selectedPremiums,
  offer,
  requestedOffers,

  privateLiabilityInquiry,
  privateLiabilityInquiryError,
  privateLiabilityPremiums,
  selectedPrivateLiabilityPremiums,

  mobiliarInquiry,
  mobiliarInquiryError,
  mobiliarPremiums,
  mobiliarSelectedPremiums,
  mobiliarOffer,
  mobiliarRequestedOffers,
};

/**
 * Type definition for the global state type containing all reducers and substates
 */
export type RootState = AggregateRootState<typeof reducers>;

/**
 * Type definition for all possible actions fired by the application
 */
export type RootAction =
  | loadingAC.ActionType
  | languageAC.ActionType
  | inquiryAC.ActionType
  | inquiryErrorAC.ActionType
  | offerAC.ActionType
  | premiumsAC.ActionType
  | requestedOffersAC.ActionType
  | selectedPremiumsAC.ActionType
  | resultSortingOrderAC.ActionType
  | featureFlagsAC.ActionType
  | alertsAC.ActionType
  | privateLiabilityInquiryAC.ActionType
  | privateLiabilityInquiryErrorAC.ActionType
  | privateLiabilityPremiumsAC.ActionType
  | selectedPrivateLiabilityPremiumsAC.ActionType
  | mobiliarInquiryAC.ActionType
  | mobiliarInquiryErrorAC.ActionType
  | mobiliarPremiumsAC.ActionType
  | mobiliarSelectedPremiumsAC.ActionType
  | mobiliarOfferAC.ActionType
  | mobiliarRequestedOffersAC.ActionType;
// define more action types here ...

// root reducer instance, containing all child reducers
export const rootReducer = combineReducers(reducers);

/**
 * Typed hook to call selectors bound to the state
 * @author Jan Biasi <jan.biasi(@)namics.com>
 */
export const useSelector = useSelectorNative as TypedUseSelectorHook<RootState>;

/**
 * Typed hook which returns a dispatch function to fire state bound actions
 * @author Jan Biasi <jan.biasi(@)namics.com>
 */
export const useDispatch = () => useDispatchNative<Dispatch<RootAction>>();
