export const updateLanguagePath = (currentLangCode: string, targetLangCode = 'de') => {
  if (typeof window !== 'undefined') {
    const currentUrl = new URL(window.location.href);
    let pathname = currentUrl.pathname.replace(`/${currentLangCode}/`, `/${targetLangCode}/`);

    if (!pathname.startsWith(`/${targetLangCode}/`)) {
      pathname = `/${targetLangCode}${pathname}`;
    }

    currentUrl.pathname = pathname;
    window.location.href = currentUrl.toString();
  }
};
