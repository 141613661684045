import { HeaderLanguageItem } from '@scout24ch/fs24-ui';
import { Button, fs24, Header } from '@scout24ch/fs24-design-system';
import { useDispatch } from '../../../state/rootReducer';
import { updateLanguage, fetchLanguage } from '../../../state/language/language.actionCreators';
import { Language } from '../../../enums';
import { LogoRenderer } from '../../LogoRenderer';

export function MobiliarHeader(): JSX.Element {
  const dispatch = useDispatch();
  const onLanguageClick = (languageItem: HeaderLanguageItem) => {
    dispatch(updateLanguage(languageItem.shortCode as Language));
    dispatch(fetchLanguage());
  };

  return (
    <fs24.div
      w={['100%', null, null, 'container.lg']}
      margin="auto"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      __css={{
        header: {
          nav: {
            width: 'auto',
            '> a': {
              display: 'none',
            },
            'div:last-of-type': {
              display: 'none',
            },
            'ul#main-navigation': {
              width: ['100vw', null, 'auto'],
              left: ['-88vw', null, 'auto'],
            },
          },
        },
      }}
    >
      <LogoRenderer insurance="mobiliar" svgHeight="34" svgWidth="177" />
      <Header
        logo=""
        logoHref=""
        menu={[]}
        notifications={[]}
        profileFlyout={null}
        renderLocaleLink={(currentLocale, locale, _href, children) => (
          <Button
            role="menuitemradio"
            variant="unstyled"
            fontWeight="normal"
            aria-checked={locale === currentLocale}
            onClick={() => onLanguageClick({ label: children, shortCode: locale })}
          >
            {children}
          </Button>
        )}
      />
    </fs24.div>
  );
}

MobiliarHeader.displayName = 'MobiliarHeader';
