import { ProductType } from '../enums';
import { getHouseholdErrors } from '../state/inquiryError/inquiryError.selectors';
import { getMobiliarInquiryErrors } from '../state/mobiliar/inquiryError/inquiryError.selectors';
import { getPrivateLiabilityFunnelErrors } from '../state/privateLiabilityError/privateLiabilityInquiryError.selectors';
import { useSelector } from '../state/rootReducer';
import { useProductType } from './useProductType';

export const useGetInquiryErrors = () => {
  const productType = useProductType();
  const householdErrors = useSelector(getHouseholdErrors);
  const mobiliarErrors = useSelector(getMobiliarInquiryErrors);
  const privateLiabilityErrors = useSelector(getPrivateLiabilityFunnelErrors);

  if (productType === ProductType.Household) {
    return householdErrors;
  }

  if (productType === ProductType.Mobiliar) {
    return mobiliarErrors;
  }

  return privateLiabilityErrors;
};
