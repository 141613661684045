import { Offer } from '../../../enums';
import { ActionType } from './offer.actionCreators';
import * as actions from './offer.actionTypes';
import * as types from './types';

export type OfferState = Readonly<types.Offer>;

export const initialState: OfferState = {
  [Offer.Gender]: '',
  [Offer.FirstName]: '',
  [Offer.LastName]: '',
  [Offer.DateOfBirth]: '',
  [Offer.StreetName]: '',
  [Offer.StreetNumber]: '',
  [Offer.PostalCode]: '',
  [Offer.City]: '',
  [Offer.PhoneNumber]: '',
  [Offer.Email]: '',
  [Offer.Nationality]: '',
  [Offer.Language]: '',
  [Offer.CompanyTermsAccepted]: false,
  [Offer.ResidencePermit]: '',
  [Offer.ResidencePermitSince]: '',
  [Offer.HouseholdMembers]: [],
  [Offer.isDirectDeal]: false,
};

const offerReducer = (state: OfferState = initialState, action: ActionType) => {
  switch (action.type) {
    case actions.SET_MOBILIAR_MEMBERS_FIELDS:
      return { ...state, [Offer.HouseholdMembers]: action.newValues };
    case actions.SET_OFFER_FIELDS:
      return {
        ...action.newValues,
        residencePermit: action.newValues.nationality === 'ch' ? '' : action.newValues.residencePermit,
        residencePermitSince: action.newValues.nationality === 'ch' ? '' : action.newValues.residencePermitSince,
      };
    case actions.SET_EMAIL_FIELD:
      return { ...state, email: action.newValue };
    default:
      return state;
  }
};

export default offerReducer;
