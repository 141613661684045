import axios from 'axios';
import { HOUSEHOLD_API_BASE_URL } from '../config/api';
import { logger } from '../common/log/logger';

logger.info(`Application running with API ${HOUSEHOLD_API_BASE_URL}`);

/**
 * Middleware for requests, sends errors directly to the logging service.
 * Exported to enable support for ejecting the interceptor at any time
 * @example
 * axios.interceptors.request.eject(defaultRequestInterceptor);
 */
export const defaultRequestInterceptor = axios.interceptors.request.use(
  (config) => {
    // can modify the config for all requests
    return config;
  },
  (err) => {
    // TODO: log errors to logger for request errors
    return Promise.reject(err);
  },
);

/**
 * Middleware for responses, sends errors directly to the logging service.
 * Exported to enable support for ejecting the interceptor at any time
 * @example
 * axios.interceptors.response.eject(defaultRequestInterceptor);
 */
export const defaultResponseInterceptor = axios.interceptors.response.use(
  (response) => {
    // can modify the response model for all responses
    return response;
  },
  (err) => {
    // TODO: log errors to logger for response errors
    return Promise.reject(err);
  },
);
