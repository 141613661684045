import { validateDate } from '@scout24ch/fs24-forms';

const LOW_INDEX = 0;
const HIGH_INDEX = 1;

const ageGroups = {
  '0-17': [0, 17],
  '18-24': [18, 24],
  '25-34': [25, 34],
  '35-44': [35, 44],
  '45-54': [45, 54],
  '55-64': [55, 64],
  '65+': [65, 1000],
};

export const mapAgeToAgeGroup = (age: number) => {
  for (const [ageGroup, ageRange] of Object.entries(ageGroups)) {
    if (age >= ageRange[LOW_INDEX] && age <= ageRange[HIGH_INDEX]) {
      return ageGroup;
    }
  }
};

export const mapDateOfBirthToAge = (dateOfBirth: string, currentDay: string) => {
  if (validateDate(dateOfBirth) !== null || validateDate(currentDay) !== null) {
    return undefined;
  }
  const birthDate = new Date(dateOfBirth);
  const today = new Date(currentDay);
  const age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

export const mapDateOfBirthToAgeGroup = (dateOfBirth: string, currentDay: string) => {
  const age = mapDateOfBirthToAge(dateOfBirth, currentDay);
  return age === undefined ? undefined : mapAgeToAgeGroup(age);
};
