import { cookieStorage } from '@scout24ch/fs24-utils';
import { KEY_LAST_INQUIRY_KEY } from '../common/constants';
import { environment } from '../common/environment';

export const saveLastInquiryKey = (requestId: string, label: string, currentLangauge: string) => {
  const value = JSON.stringify({
    requestId,
    label,
    timestamp: new Date(),
    path: `/${currentLangauge}/result/${requestId}`,
  });
  const endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 2);
  cookieStorage.setSharedItem(KEY_LAST_INQUIRY_KEY, value, environment.FS_MAIN_DOMAIN);
};
