/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const BaloiseLogoIt = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--baloise-logo-it ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 190 45"
    fill="none"
    {...props}
  >
    <path
      d="M57.1433 40.6297H55.2115L56.1463 37.7632L57.1433 40.6297ZM57.7665 42.4369L58.3274 43.9948H60.8199L57.4549 35.0214H54.9L51.4726 43.9948H53.9653L54.5884 42.4369H57.7665Z"
      fill="#004A93"
    />
    <path
      d="M65.9921 39.4456C65.6805 39.2588 65.182 39.1341 64.8081 39.1341C64.5588 39.1341 64.1226 39.2588 64.1226 39.5703C64.1226 40.0065 64.8081 40.0688 65.1197 40.1312C66.1167 40.3181 66.9268 40.8167 66.9268 41.9383C66.9268 43.5585 65.4312 44.1817 64.0603 44.1817C63.1879 44.1817 62.3155 43.9324 61.5678 43.4962L62.3778 42.0006C62.814 42.3123 63.5619 42.6238 64.1226 42.6238C64.3719 42.6238 64.8081 42.4991 64.8081 42.1876C64.8081 41.6891 64.1226 41.6891 63.4372 41.5021C62.7517 41.3152 62.0663 41.0036 62.0663 39.8818C62.0663 38.3864 63.4372 37.7008 64.7458 37.7008C65.4312 37.7008 66.1167 37.8255 66.7399 38.0747L65.9921 39.4456Z"
      fill="#004A93"
    />
    <path
      d="M72.4107 39.4456C72.099 39.2588 71.6006 39.1341 71.2266 39.1341C70.9774 39.1341 70.5412 39.2588 70.5412 39.5703C70.5412 40.0065 71.2266 40.0688 71.5383 40.1312C72.5352 40.3181 73.3454 40.8167 73.3454 41.9383C73.3454 43.5585 71.8498 44.1817 70.4789 44.1817C69.6065 44.1817 68.7341 43.9324 67.9862 43.4962L68.7963 42.0006C69.2326 42.3123 69.9803 42.6238 70.5412 42.6238C70.7904 42.6238 71.2266 42.4991 71.2266 42.1876C71.2266 41.6891 70.5412 41.6891 69.8557 41.5021C69.1703 41.3152 68.4848 41.0036 68.4848 39.8818C68.4848 38.3864 69.8557 37.7008 71.1644 37.7008C71.8498 37.7008 72.5353 37.8255 73.1584 38.0747L72.4107 39.4456Z"
      fill="#004A93"
    />
    <path
      d="M76.9596 43.9947H74.7786V37.8878H76.9596V43.9947ZM77.0843 35.5198C77.0843 36.2052 76.5234 36.7037 75.9002 36.7037C75.2148 36.7037 74.654 36.143 74.654 35.5198C74.654 34.8343 75.2148 34.2734 75.9002 34.2734C76.5234 34.3357 77.0843 34.8343 77.0843 35.5198Z"
      fill="#004A93"
    />
    <path
      d="M83.2535 39.8818C82.942 39.6326 82.5681 39.4456 82.1319 39.4456C81.3217 39.4456 80.6363 40.0688 80.6363 40.9412C80.6363 41.8136 81.2594 42.4368 82.1319 42.4368C82.5058 42.4368 82.942 42.3123 83.1912 42.0006V43.8077C82.6926 44.0571 82.1941 44.1817 81.6956 44.1817C79.8885 44.1817 78.3929 42.8108 78.3929 41.0036C78.3929 39.0718 79.8885 37.7008 81.8202 37.7008C82.3188 37.7008 82.8173 37.8255 83.2535 38.0747V39.8818Z"
      fill="#004A93"
    />
    <path
      d="M86.9924 37.8878V41.1282C86.9924 41.876 87.1794 42.4367 88.0518 42.4367C88.9242 42.4367 89.1112 41.876 89.1112 41.1282V37.8878H91.2922V41.5643C91.2922 43.5585 89.859 44.1817 88.0518 44.1817C86.1824 44.1817 84.8114 43.5585 84.8114 41.5643V37.8878H86.9924Z"
      fill="#004A93"
    />
    <path
      d="M95.1558 38.8848C95.592 38.1371 96.3397 37.8254 97.1499 37.8254H97.5861V39.8819C97.2744 39.695 97.0252 39.695 96.6513 39.695C95.4673 39.695 95.1558 40.5051 95.1558 41.5643V44.057H92.9748V37.9501H95.1558V38.8848Z"
      fill="#004A93"
    />
    <path
      d="M102.01 39.5703C102.883 39.5703 103.381 40.1935 103.381 40.9412C103.381 41.6891 102.883 42.3123 102.01 42.3123C101.138 42.3123 100.64 41.6891 100.64 40.9412C100.64 40.1935 101.138 39.5703 102.01 39.5703ZM105.562 37.8878H103.381V38.5109C102.945 37.9502 102.197 37.7008 101.45 37.7008C99.6425 37.7008 98.3961 39.1964 98.3961 40.9412C98.3961 42.6861 99.6425 44.1817 101.45 44.1817C102.197 44.1817 102.945 43.9324 103.319 43.3092V43.9947H105.5V37.8878H105.562Z"
      fill="#004A93"
    />
    <path
      d="M112.978 42.1875V43.9947H106.809L109.675 39.6949H107.183V37.8878H113.29L110.423 42.1875H112.978Z"
      fill="#004A93"
    />
    <path
      d="M116.717 43.9947H114.536V37.8878H116.717V43.9947ZM116.842 35.5198C116.842 36.2052 116.281 36.7037 115.595 36.7037C114.91 36.7037 114.349 36.143 114.349 35.5198C114.349 34.8343 114.91 34.2734 115.595 34.2734C116.281 34.2734 116.842 34.8343 116.842 35.5198Z"
      fill="#004A93"
    />
    <path
      d="M121.827 39.5703C122.699 39.5703 123.198 40.1935 123.198 40.9412C123.198 41.6891 122.699 42.3123 121.827 42.3123C120.955 42.3123 120.456 41.6891 120.456 40.9412C120.394 40.1935 120.955 39.5703 121.827 39.5703ZM121.827 37.7008C119.833 37.7008 118.15 38.8849 118.15 40.9412C118.15 42.9977 119.895 44.1817 121.827 44.1817C123.821 44.1817 125.503 42.9977 125.503 40.9412C125.503 38.8849 123.759 37.7008 121.827 37.7008Z"
      fill="#004A93"
    />
    <path
      d="M129.055 38.6979C129.554 37.9502 130.177 37.7008 131.05 37.7008C132.67 37.7008 133.355 38.7602 133.355 40.2558V44.0571H131.174V41.0659C131.174 40.505 131.299 39.4456 130.177 39.4456C129.305 39.4456 129.055 40.1312 129.055 40.8789V44.0571H126.874V37.9502H129.055V38.6979Z"
      fill="#004A93"
    />
    <path
      d="M137.156 43.9947H134.975V37.8878H137.156V43.9947ZM137.344 35.5198C137.344 36.2052 136.783 36.7037 136.097 36.7037C135.412 36.7037 134.913 36.143 134.913 35.5198C134.913 34.8343 135.474 34.2734 136.097 34.2734C136.783 34.3357 137.344 34.8343 137.344 35.5198Z"
      fill="#004A93"
    />
    <path
      d="M58.5143 18.1961H59.1997C61.4431 18.1961 64.8705 17.8846 64.8705 21.0626C64.8705 23.9915 61.4431 23.8668 59.3867 23.8668H58.5143V18.1961ZM62.1908 29.1636C67.0515 29.1636 71.7874 27.3564 71.7874 21.8104C71.7874 18.1338 70.1672 15.5788 66.366 15.0803V15.0179C68.5471 13.9587 69.2948 11.9645 69.2948 9.59647C69.2948 4.54894 66.1167 2.74182 61.4431 2.74182H51.5972V29.226H62.1908V29.1636ZM58.5143 7.91405H59.0128C60.8822 7.91405 63.0009 8.0386 63.0009 10.5313C63.0009 12.7746 61.0691 13.1485 59.262 13.1485H58.5143V7.91405Z"
      fill="#004A93"
    />
    <path
      d="M84.1259 16.0773C86.7432 16.0773 88.2388 17.9468 88.2388 20.1278C88.2388 22.3711 86.7432 24.1783 84.1259 24.1783C81.5087 24.1783 80.0131 22.4334 80.0131 20.1278C80.0131 17.9468 81.5087 16.0773 84.1259 16.0773ZM94.4081 11.1544H87.9895V12.8993C86.6809 11.3413 84.4999 10.5312 82.4435 10.5312C77.1466 10.5312 73.4077 14.9556 73.4077 20.1278C73.4077 25.2999 77.1466 29.7244 82.5058 29.7244C84.6246 29.7244 86.8056 28.9143 87.9273 27.1072H87.9895V29.1012H94.4081V11.1544Z"
      fill="#004A93"
    />
    <path
      d="M109.551 15.7657C108.554 15.2671 107.183 14.8933 106.123 14.8933C105.376 14.8933 104.067 15.2049 104.067 16.1395C104.067 17.3859 106.123 17.5729 106.996 17.6975C109.987 18.3206 112.23 19.6916 112.23 23.0566C112.23 27.7926 107.931 29.7867 103.693 29.7867C101.138 29.7867 98.5832 29.039 96.4644 27.7303L98.7702 23.2436C100.079 24.1783 102.26 25.0507 103.818 25.0507C104.628 25.0507 105.874 24.6769 105.874 23.6798C105.874 22.2465 103.818 22.1842 101.824 21.6857C99.8294 21.1872 97.8353 20.1901 97.8353 16.9497C97.8353 12.5253 101.824 10.5312 105.812 10.5312C107.868 10.5312 109.862 10.8428 111.732 11.7152L109.551 15.7657Z"
      fill="#004A93"
    />
    <path
      d="M120.954 29.1637H114.536V11.1545H120.954V29.1637ZM121.391 4.23739C121.391 6.2316 119.77 7.78947 117.776 7.78947C115.782 7.78947 114.224 6.16919 114.224 4.23739C114.224 2.24332 115.844 0.685453 117.776 0.685453C119.77 0.623175 121.391 2.24332 121.391 4.23739Z"
      fill="#004A93"
    />
    <path d="M130.551 29.1637H124.132V0H130.551V29.1637Z" fill="#004A93" />
    <path
      d="M139.275 17.5106C139.649 15.641 141.207 14.7063 143.077 14.7063C144.821 14.7063 146.379 15.828 146.691 17.5106H139.275ZM152.611 20.564C152.611 14.4571 148.997 10.5935 142.765 10.5935C136.907 10.5935 132.794 14.2079 132.794 20.1901C132.794 26.4217 137.282 29.7867 143.201 29.7867C147.313 29.7867 151.364 27.8549 152.361 23.6175H146.192C145.507 24.8014 144.509 25.2376 143.139 25.2376C140.522 25.2376 139.15 23.8044 139.15 21.2495H152.611V20.564Z"
      fill="#004A93"
    />
    <path
      d="M166.445 15.7657C165.448 15.2671 164.077 14.8933 163.017 14.8933C162.269 14.8933 160.96 15.2049 160.96 16.1395C160.96 17.3859 163.017 17.5729 163.889 17.6975C166.881 18.3206 169.124 19.6916 169.124 23.0566C169.124 27.7926 164.825 29.7867 160.587 29.7867C158.032 29.7867 155.477 29.039 153.359 27.7303L155.664 23.2436C156.973 24.1783 159.154 25.0507 160.712 25.0507C161.522 25.0507 162.768 24.6769 162.768 23.6798C162.768 22.2465 160.712 22.1842 158.717 21.6857C156.724 21.1872 154.73 20.1901 154.73 16.9497C154.73 12.5253 158.717 10.5312 162.706 10.5312C164.762 10.5312 166.757 10.8428 168.626 11.7152L166.445 15.7657Z"
      fill="#004A93"
    />
    <path
      d="M176.664 17.5106C177.038 15.641 178.596 14.7063 180.466 14.7063C182.21 14.7063 183.768 15.828 184.08 17.5106H176.664ZM190 20.564C190 14.4571 186.386 10.5935 180.154 10.5935C174.296 10.5935 170.183 14.2079 170.183 20.1901C170.183 26.4217 174.671 29.7867 180.59 29.7867C184.704 29.7867 188.753 27.8549 189.751 23.6175H183.581C182.896 24.8014 181.899 25.2376 180.528 25.2376C177.911 25.2376 176.54 23.8044 176.54 21.2495H190V20.564Z"
      fill="#004A93"
    />
    <path
      d="M27.7928 25.1131L33.8373 31.22L21.0627 43.9947L0 22.9321L21.0627 1.86951L39.9442 20.8134H48.8553V29.1637H35.8938L21.0627 14.3326L12.2762 23.119L21.0627 31.8432L27.7928 25.1131Z"
      fill="#004A93"
    />
  </svg>
);

BaloiseLogoIt.displayName = 'BaloiseLogoIt';

export default BaloiseLogoIt;
