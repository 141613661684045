import * as React from 'react';
import { DeviceType, getCurrentDeviceType } from '@scout24ch/fs24-utils';

export const useIsDesktopDevice = () => {
  const [isDesktopDevice, setIsDesktopDevice] = React.useState(false);
  React.useEffect(() => {
    setIsDesktopDevice(getCurrentDeviceType() === DeviceType.DESKTOP);
  }, []);

  return isDesktopDevice;
};
