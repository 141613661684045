import { Field, HouseholdType, OccupancyType, PossessionType, PriceRange, Size } from '../../enums';
import { City, Damage } from '../../types';
import { ActionType } from './inquiry.actionCreators';
import * as actions from './inquiry.actionTypes';
export interface InternalInquiryState {
  [Field.PossessionType]: PossessionType | null;
  [Field.HouseholdType]: HouseholdType | null;
  [Field.HouseholdOccupancyType]: OccupancyType | null;
  [Field.IsGroundFloorApartment]: boolean | null;
  [Field.NumberOfRooms]: Size | null | '';
  [Field.IsMassive]: boolean | null;
  [Field.City]: City;
  [Field.Furnishing]: PriceRange | null;
  [Field.Clothing]: PriceRange | null;
  [Field.ElectricalAppliances]: PriceRange | null;
  [Field.DateOfBirth]: string | null;
  [Field.EmailAddress]: string | null;
  [Field.IsDogOwner]: boolean | null;
  [Field.NumberOfDogs]: number | null;
  [Field.NumberOfInsurees]: string | null;
  [Field.NumberOfResidentsAgedUnder16]: string | null;
  [Field.ChildrenDatesOfBirth]: string[];
  [Field.GrossNegligence]: boolean | null;
  [Field.NonOwnerDriverInsurance]: boolean | null;
  [Field.SelfInflictedDamagesAmount]: number | null;
  [Field.TheftNotAtHomeAmount]: number | null;
  [Field.PrivateLiability]: boolean;
  [Field.TheftNotAtHome]: boolean;
  [Field.LuggageInsurance]: boolean;
  [Field.CyberInsurance]: boolean;
  [Field.GlassFurniture]: boolean;
  [Field.SelfInflictedDamages]: boolean;
  [Field.InsuranceStartDate]: string | null;
  [Field.InsuranceSum]: number;
  [Field.RecommendedInsuranceSum]: number;
  identifier: string | null;
  [Field.HasReceivedCompensationForHousehold]: boolean | null;
  [Field.HasReceivedCompensationForPrivateLiability]: boolean | null;
  [Field.ContractTerminatedByPreviousInsurer]: boolean | null;
  [Field.NumberOfHouseholdDamageCompensations]: number | null;
  [Field.NumberOfPrivateLiabilityDamageCompensations]: number | null;
  [Field.HouseholdDamages]: Damage[];
  [Field.PrivateLiabilityDamages]: Damage[];
  [Field.PreviousInsurer]: null | string;
  [Field.ReasonForCancellation]: null | string;
  [Field.DataProtectionAccepted]: boolean;
  goBackToFunnel: boolean;
}

export type InquiryState = Readonly<InternalInquiryState>;

export const initialState: InquiryState = {
  [Field.PossessionType]: null,
  [Field.HouseholdType]: null,
  [Field.HouseholdOccupancyType]: null,
  [Field.IsGroundFloorApartment]: null,
  [Field.IsMassive]: null,
  [Field.NumberOfRooms]: null,
  [Field.City]: { postalCode: null, name: null, canton: null },
  [Field.Furnishing]: null,
  [Field.Clothing]: null,
  [Field.ElectricalAppliances]: null,
  [Field.DateOfBirth]: null,
  [Field.EmailAddress]: null,
  [Field.IsDogOwner]: null,
  [Field.NumberOfDogs]: null,
  [Field.NumberOfInsurees]: null,
  [Field.NumberOfResidentsAgedUnder16]: null,
  [Field.ChildrenDatesOfBirth]: [],
  [Field.GrossNegligence]: null,
  [Field.NonOwnerDriverInsurance]: null,
  [Field.SelfInflictedDamagesAmount]: null,
  [Field.TheftNotAtHomeAmount]: null,
  [Field.PrivateLiability]: false,
  [Field.TheftNotAtHome]: false,
  [Field.LuggageInsurance]: false,
  [Field.CyberInsurance]: false,
  [Field.GlassFurniture]: false,
  [Field.SelfInflictedDamages]: false,
  [Field.InsuranceStartDate]: null,
  [Field.InsuranceSum]: 0,
  identifier: null,
  [Field.RecommendedInsuranceSum]: 0,
  [Field.HasReceivedCompensationForHousehold]: null,
  [Field.HasReceivedCompensationForPrivateLiability]: null,
  [Field.ContractTerminatedByPreviousInsurer]: null,
  [Field.NumberOfHouseholdDamageCompensations]: null,
  [Field.NumberOfPrivateLiabilityDamageCompensations]: null,
  [Field.HouseholdDamages]: [],
  [Field.PrivateLiabilityDamages]: [],
  [Field.PreviousInsurer]: null,
  [Field.ReasonForCancellation]: null,
  [Field.DataProtectionAccepted]: true,
  goBackToFunnel: false,
};

const updateHouseholdDamages = (damages: Damage[], index: number, field: string, newValue: string) => {
  const newDamage = { ...damages[index], [field]: newValue };

  return [...damages.slice(0, index), newDamage, ...damages.slice(index + 1)];
};

const inquiryReducer = (state: InquiryState = initialState, action: ActionType): InquiryState => {
  switch (action.type) {
    case actions.SET_HOUSEHOLD_FIELD:
      // Resolves 'Maximum update depth exceeded' error on select dropdown field. - temporary fix
      if (
        Array.isArray(state[action.field]) &&
        Array.isArray(action.newValue) &&
        !state[action.field].length &&
        !action.newValue.length
      ) {
        return state;
      }

      if (state[action.field] !== action.newValue) {
        return { ...state, [action.field]: action.newValue };
      }

      return state;
    case actions.SET_INQUIRY_FIELDS:
      return action.newInquiry;
    case actions.SET_INITIAL_DAMAGES_ARRAY:
      return {
        ...state,
        [action.damagesType]: action.initialArray,
      };
    case actions.SET_DAMAGE_DETAIL:
      const { damageType, index, field, newValue } = action;
      // Resolves 'Maximum update depth exceeded' error on select dropdown field.
      if (state[damageType][index][field] !== newValue) {
        return {
          ...state,
          [action.damageType]: updateHouseholdDamages(
            state[action.damageType] as Damage[],
            action.index,
            action.field,
            action.newValue,
          ),
        };
      }

      return state;
    case actions.SET_QUICK_ADJUST_FIELDS:
      let theftNotAtHomeAmount = state.theftNotAtHomeAmount;
      let selfInflictedDamagesAmount = state.selfInflictedDamagesAmount;

      if (action.newValues.theftNotAtHome) {
        theftNotAtHomeAmount = theftNotAtHomeAmount === null ? 1000 : theftNotAtHomeAmount;
      } else {
        theftNotAtHomeAmount = null;
      }
      if (action.newValues.selfInflictedDamages) {
        selfInflictedDamagesAmount = selfInflictedDamagesAmount === null ? 2000 : selfInflictedDamagesAmount;
      } else {
        selfInflictedDamagesAmount = null;
      }

      return {
        ...state,
        [Field.TheftNotAtHome]: action.newValues.theftNotAtHome,
        [Field.GlassFurniture]: action.newValues.glassFurniture,
        [Field.LuggageInsurance]: action.newValues.luggageInsurance,
        [Field.CyberInsurance]: action.newValues.cyberInsurance,
        [Field.SelfInflictedDamages]: action.newValues.selfInflictedDamages,
        [Field.SelfInflictedDamagesAmount]: selfInflictedDamagesAmount,
        [Field.TheftNotAtHomeAmount]: theftNotAtHomeAmount,

        insuranceSum: action.newValues.insuranceSum,
      };
    case actions.SET_CHILD_DATE_OF_BIRTH:
      const newArray = [...state.childrenDatesOfBirth];
      newArray[action.index] = action.dateOfBirth;
      return {
        ...state,
        [Field.ChildrenDatesOfBirth]: newArray,
      };
    case actions.UPDATE_CHILDREN_DATES_OF_BIRTH_ARRAY_LENGTH:
      return {
        ...state,
        [Field.ChildrenDatesOfBirth]: state.childrenDatesOfBirth.slice(0, action.newNumberOfChildren),
      };
    default:
      return state;
  }
};

export default inquiryReducer;
