/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const TcsLogo = (props: IconProps) => (
  <svg className={`a-icon a-icon--tcs-logo ${props.className || ''}`} width="1em" height="1em" id="svg2" {...props}>
    <defs id="defs4" />
    <g transform="translate(41.188047,644.61135)" id="layer1">
      <g transform="translate(35.433052,35.432597)" id="g2679">
        <path
          d="M 277.70959,46.043556 C 103.50606,46.043556 -37.708151,-95.187994 -37.708151,-269.40897 C -37.708151,-399.40049 41.181109,-511.27915 153.49472,-559.5627 L 151.72,-624.60195 C 151.72,-624.60195 151.4068,-641.13145 168.21468,-641.13145 L 277.70959,-641.13145 L 387.18712,-641.13145 C 404.01241,-641.13145 403.69924,-624.60195 403.69924,-624.60195 L 401.92446,-559.5627 C 514.22068,-511.27915 593.12733,-399.40049 593.12733,-269.40897 C 593.12733,-95.187994 451.91319,46.043556 277.70959,46.043556"
          id="path11872"
          style={{
            fill: '#ffffff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 277.70959,46.043556 C 103.50606,46.043556 -37.708151,-95.187994 -37.708151,-269.40897 C -37.708151,-399.40049 41.181109,-511.27915 153.49472,-559.5627 L 151.72,-624.60195 C 151.72,-624.60195 151.4068,-641.13145 168.21468,-641.13145 L 277.70959,-641.13145 L 387.18712,-641.13145 C 404.01241,-641.13145 403.69924,-624.60195 403.69924,-624.60195 L 401.92446,-559.5627 C 514.22068,-511.27915 593.12733,-399.40049 593.12733,-269.40897 C 593.12733,-95.187994 451.91319,46.043556 277.70959,46.043556 z"
          id="path11876"
          style={{
            fill: 'none',
            stroke: '#003f8a',
            strokeWidth: 6.95979166,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
        />
        <path
          d="M 277.70959,-387.40696 C 379.02675,-420.86615 379.11378,-511.50001 379.11378,-511.50001 L 382.24564,-617.74125 L 277.70959,-617.74125 L 173.17353,-617.74125 L 176.30543,-511.50001 C 176.30543,-511.50001 176.37505,-420.86615 277.70959,-387.40696"
          id="path11880"
          style={{
            fill: '#e2001a',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 207.44188,-533.20941 L 257.20436,-533.20941 L 257.20436,-582.58915 L 297.81477,-582.58915 L 297.81477,-533.20941 L 347.57728,-533.20941 L 347.57728,-492.61642 L 297.81477,-492.61642 L 297.81477,-443.25413 L 257.20436,-443.25413 L 257.20436,-492.61642 L 207.44188,-492.61642 L 207.44188,-533.20941 z"
          id="path11884"
          style={{
            fill: '#ffffff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 305.6149,-218.20231 C 297.28052,-212.96504 287.62385,-210.19855 277.70614,-210.19855 C 268.2582,-210.19855 258.84509,-212.89546 250.45854,-217.9761 L 249.79738,-218.3937 L 249.1014,-218.04571 L 56.906749,-121.37423 L 57.045939,-121.44381 C 56.697939,-121.32199 53.792219,-120.17368 52.904849,-117.84214 C 52.487279,-116.72858 52.574299,-115.54537 53.165879,-114.39699 L 53.200639,-114.32742 L 53.252839,-114.2578 C 103.83316,-40.205684 187.73341,4.7893656 277.68869,6.1117656 C 367.6788,4.7893656 451.57913,-40.205684 502.15937,-114.2578 L 502.21157,-114.32742 L 502.2464,-114.39699 C 502.85536,-115.54537 502.94238,-116.72858 502.50736,-117.84214 C 501.61999,-120.17368 498.69689,-121.32199 498.36627,-121.44381 L 498.52291,-121.37423 L 306.98946,-218.27189 L 306.27606,-218.61988 L 305.6149,-218.20231 z"
          id="path11896"
          style={{
            fill: '#003f8a',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 514.84361,-148.44612 C 517.07076,-147.14118 522.36016,-146.34079 526.43171,-152.50022 L 526.48387,-152.58721 L 526.53607,-152.6742 C 544.24874,-189.83947 553.22684,-229.64953 553.22684,-271.00805 C 553.22684,-374.46538 494.97341,-468.14415 401.20761,-515.48813 L 399.17189,-516.51473 L 399.17189,-514.23534 L 399.17189,-513.38279 C 399.17189,-512.44322 397.72772,-419.00804 299.89045,-374.30877 L 299.02048,-373.90858 L 299.07268,-372.95159 L 302.36118,-309.73931 L 302.41339,-308.9563 L 303.09196,-308.57355 C 319.76065,-299.52581 330.11338,-281.91754 330.11338,-262.62154 C 330.11338,-262.04733 330.07855,-261.54274 330.04373,-260.98594 L 329.99156,-260.09857 L 329.93936,-259.24601 C 329.93936,-259.26343 330.67014,-258.82844 330.67014,-258.82844 C 330.67014,-258.82844 514.82623,-148.44612 514.84361,-148.44612"
          id="path11900"
          style={{
            fill: '#003f8a',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 225.36518,-259.72623 C 225.31298,-260.63102 225.29553,-261.64018 225.29553,-262.61458 C 225.29553,-281.64961 235.71783,-299.20567 252.49093,-308.46218 L 253.1695,-308.82755 L 253.20432,-309.61056 L 256.33622,-372.96204 L 256.38843,-373.91903 L 255.51842,-374.30181 C 157.68119,-419.00107 156.23702,-512.45366 156.23702,-513.39324 L 156.23702,-514.22842 L 156.23702,-516.50776 L 154.20126,-515.48116 C 60.435499,-468.15459 2.1820292,-374.45841 2.1820292,-271.00109 C 2.1820292,-229.65994 11.177579,-189.84995 28.890209,-152.66724 L 28.925039,-152.58025 L 28.994649,-152.49326 C 33.048679,-146.33382 38.338149,-147.15159 40.565259,-148.43916 C 40.582709,-148.45654 224.68657,-258.45607 224.68657,-258.45607 L 225.41738,-258.87364 L 225.36518,-259.72623 z"
          id="path11904"
          style={{
            fill: '#003f8a',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 506.42224,-336.84585 C 502.07238,-372.56698 479.88808,-391.53247 442.18339,-391.74123 C 421.09519,-391.74123 403.13897,-384.93801 390.28071,-372.06242 C 376.86577,-358.66482 369.78414,-339.595 369.78414,-316.90607 C 369.78414,-294.65211 377.14412,-275.02553 390.52434,-261.66272 C 403.33036,-248.83933 421.19959,-242.07093 442.18339,-242.07093 C 483.73334,-242.07093 506.45706,-271.56302 507.18787,-299.80239 L 465.72488,-299.80239 C 463.49777,-289.06692 458.34752,-275.54751 440.94806,-275.54751 C 433.57063,-275.54751 427.41123,-277.96604 422.64377,-282.73347 C 413.23065,-292.14662 411.8213,-308.17153 411.8213,-316.90607 C 411.8213,-342.79648 422.71338,-358.26466 440.94806,-358.26466 C 459.46108,-358.26466 464.33291,-343.16188 465.56831,-336.84585 L 506.42224,-336.84585 z"
          id="path11908"
          style={{
            fill: '#ffffff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 258.88684,-70.292944 C 259.02607,-67.143634 259.56542,-64.168334 260.5572,-61.610644 C 263.3933,-53.972284 271.03166,-50.109534 283.26351,-50.109534 C 286.55201,-50.109534 294.72975,-50.631584 299.54942,-55.468584 C 301.98537,-57.887164 303.20329,-61.019024 303.20329,-64.759954 C 303.20329,-73.598864 295.70416,-75.739024 270.80548,-82.820584 L 268.33478,-83.516564 C 245.80245,-89.850014 222.48717,-96.426984 222.48717,-123.65717 C 222.48717,-136.18477 226.52383,-146.55487 234.49277,-154.50643 C 244.56708,-164.59812 261.0792,-170.37477 279.80099,-170.37477 C 301.09796,-170.37477 336.83654,-164.49372 339.04627,-125.67551 L 299.67123,-125.67551 C 299.86262,-130.35595 297.80949,-134.27083 293.6162,-137.05477 C 290.20592,-139.52551 285.07309,-140.96964 279.59222,-140.96964 C 275.48596,-140.96964 262.08836,-140.15184 262.08836,-129.78181 C 262.8191,-120.47307 276.49511,-117.18456 292.34608,-113.37405 C 296.57412,-112.34752 300.94139,-111.30354 305.30862,-110.08555 C 325.05709,-104.60473 345.25787,-95.504784 345.25787,-69.440394 C 345.25787,-24.219164 294.83416,-20.704474 279.38342,-20.704474 C 269.11772,-20.704474 218.27643,-23.140354 216.84971,-70.292944 L 258.88684,-70.292944 z"
          id="path11912"
          style={{
            fill: '#ffffff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M 149.57984,-245.53173 L 149.57984,-353.77389 L 190.34685,-353.77389 L 190.34685,-388.27706 L 66.775739,-388.27706 L 66.775739,-353.77389 L 107.54271,-353.77389 L 107.54271,-245.53173 L 149.57984,-245.53173 z"
          id="path11916"
          style={{
            fill: '#ffffff',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
      </g>
    </g>
  </svg>
);

TcsLogo.displayName = 'TcsLogo';

export default TcsLogo;
