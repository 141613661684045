import * as React from 'react';
import { DeviceType, getCurrentDeviceType } from '@scout24ch/fs24-utils';

export const useIsMobileDevice = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    setIsMobile(getCurrentDeviceType() === DeviceType.MOBILE);
  }, []);

  return isMobile;
};
