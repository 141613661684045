/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const BaloiseLogoFr = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--baloise-logo-fr ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 190 49"
    fill="none"
    {...props}
  >
    <path
      d="M62.1362 44.1796H60.0357L61.052 41.0627L62.1362 44.1796ZM62.8139 46.1447L63.4237 47.8387H66.1341L62.475 38.0813H59.6968L55.97 47.8387H58.6804L59.3581 46.1447H62.8139Z"
      fill="#004A93"
    />
    <path
      d="M71.7583 42.8925C71.4194 42.6892 70.8774 42.5536 70.4708 42.5536C70.1997 42.5536 69.7254 42.6892 69.7254 43.028C69.7254 43.5023 70.4708 43.57 70.8096 43.6378C71.8938 43.8412 72.7746 44.3832 72.7746 45.6029C72.7746 47.3646 71.1484 48.0423 69.6577 48.0423C68.709 48.0423 67.7604 47.7713 66.9473 47.2969L67.8282 45.6707C68.3025 46.0094 69.1156 46.3482 69.7254 46.3482C69.9964 46.3482 70.4708 46.2127 70.4708 45.8739C70.4708 45.3319 69.7254 45.3319 68.98 45.1285C68.2347 44.9252 67.4893 44.5865 67.4893 43.3668C67.4893 41.7406 68.9801 40.9951 70.4031 40.9951C71.1484 40.9951 71.8938 41.1308 72.5713 41.4018L71.7583 42.8925Z"
      fill="#004A93"
    />
    <path
      d="M78.6698 42.8925C78.3309 42.6892 77.7889 42.5536 77.3823 42.5536C77.1112 42.5536 76.6369 42.6892 76.6369 43.028C76.6369 43.5023 77.3823 43.57 77.7211 43.6378C78.8053 43.8412 79.6861 44.3832 79.6861 45.6029C79.6861 47.3646 78.0599 48.0423 76.5692 48.0423C75.6205 48.0423 74.6719 47.7713 73.8588 47.2969L74.7397 45.6707C75.214 46.0094 76.0271 46.3482 76.6369 46.3482C76.908 46.3482 77.3823 46.2127 77.3823 45.8739C77.3823 45.3319 76.6369 45.3319 75.8915 45.1285C75.1462 44.9252 74.4008 44.5865 74.4008 43.3668C74.4008 41.7406 75.8915 40.9951 77.3146 40.9951C78.0599 40.9951 78.8053 41.1308 79.4829 41.4018L78.6698 42.8925Z"
      fill="#004A93"
    />
    <path
      d="M83.6162 41.1984V44.7219C83.6162 45.535 83.8195 46.1448 84.7682 46.1448C85.7168 46.1448 85.9201 45.535 85.9201 44.7219V41.1984H88.2917V45.1963C88.2917 47.3645 86.7333 48.0422 84.7682 48.0422C82.8032 48.0422 81.2446 47.3645 81.2446 45.1963V41.1984H83.6162Z"
      fill="#004A93"
    />
    <path
      d="M92.4929 42.2824C92.9671 41.4693 93.7803 41.1305 94.6612 41.1305H95.1355V43.3666C94.7967 43.1634 94.5257 43.1634 94.1191 43.1634C92.8316 43.1634 92.4929 44.0442 92.4929 45.1961V47.9065H90.1212V41.266H92.4929V42.2824Z"
      fill="#004A93"
    />
    <path
      d="M100.014 43.028C100.963 43.028 101.505 43.7056 101.505 44.5187C101.505 45.3319 100.963 46.0094 100.014 46.0094C99.0656 46.0094 98.5236 45.3319 98.5236 44.5187C98.4558 43.7056 98.9979 43.028 100.014 43.028ZM103.809 41.1984H101.437V41.8761C100.963 41.2663 100.15 40.9951 99.4044 40.9951C97.4394 40.9951 96.0842 42.6215 96.0842 44.5187C96.0842 46.4161 97.4394 48.0423 99.4044 48.0423C100.218 48.0423 101.031 47.7713 101.437 47.0936V47.8389H103.809V41.1984Z"
      fill="#004A93"
    />
    <path
      d="M108.01 42.0793C108.552 41.2663 109.23 40.9951 110.178 40.9951C111.94 40.9951 112.685 42.1471 112.685 43.7733V47.9068H110.314V44.6542C110.314 44.0444 110.449 42.8925 109.23 42.8925C108.281 42.8925 108.01 43.6378 108.01 44.451V47.9068H105.638V41.2663H108.01V42.0793Z"
      fill="#004A93"
    />
    <path
      d="M119.394 43.3668C119.055 43.0958 118.648 42.8925 118.174 42.8925C117.293 42.8925 116.548 43.57 116.548 44.5187C116.548 45.4674 117.225 46.1449 118.174 46.1449C118.581 46.1449 119.055 46.0094 119.326 45.6707V47.6357C118.784 47.9068 118.242 48.0423 117.7 48.0423C115.735 48.0423 114.108 46.5516 114.108 44.5865C114.108 42.486 115.735 40.9951 117.835 40.9951C118.377 40.9951 118.919 41.1308 119.394 41.4018V43.3668Z"
      fill="#004A93"
    />
    <path
      d="M123.256 43.57C123.392 42.8925 124.001 42.5536 124.679 42.5536C125.289 42.5536 125.899 42.9602 126.034 43.57H123.256ZM128.135 44.6542C128.135 42.4181 126.78 40.9951 124.476 40.9951C122.307 40.9951 120.817 42.3503 120.817 44.5187C120.817 46.8226 122.443 48.0423 124.679 48.0423C126.17 48.0423 127.66 47.2969 128.067 45.7384H125.831C125.56 46.2127 125.221 46.3482 124.679 46.3482C123.73 46.3482 123.188 45.8062 123.188 44.8575H128.135V44.6542Z"
      fill="#004A93"
    />
    <path
      d="M133.895 42.8925C133.556 42.6892 133.013 42.5536 132.607 42.5536C132.336 42.5536 131.862 42.6892 131.862 43.028C131.862 43.5023 132.607 43.57 132.946 43.6378C134.031 43.8412 134.911 44.3832 134.911 45.6029C134.911 47.3646 133.285 48.0423 131.794 48.0423C130.845 48.0423 129.897 47.7713 129.084 47.2969L129.964 45.6707C130.439 46.0094 131.252 46.3482 131.862 46.3482C132.133 46.3482 132.607 46.2127 132.607 45.8739C132.607 45.3319 131.862 45.3319 131.116 45.1285C130.371 44.9252 129.626 44.5865 129.626 43.3668C129.626 41.7406 131.116 40.9951 132.54 40.9951C133.285 40.9951 134.031 41.1308 134.707 41.4018L133.895 42.8925Z"
      fill="#004A93"
    />
    <path
      d="M63.6269 19.786H64.3723C66.8117 19.786 70.5386 19.4473 70.5386 22.9031C70.5386 26.0878 66.8117 25.9523 64.5756 25.9523H63.6269V19.786ZM67.6248 31.712C72.9101 31.712 78.0599 29.7469 78.0599 23.7161C78.0599 19.7183 76.2981 16.9401 72.1648 16.3981V16.3303C74.5363 15.1784 75.3495 13.01 75.3495 10.4351C75.3495 4.94649 71.8938 2.98145 66.8117 2.98145H56.1055V31.7796H67.6248V31.712ZM63.6269 8.60557H64.1691C66.2018 8.60557 68.5057 8.74108 68.5057 11.4515C68.5057 13.8909 66.4051 14.2975 64.4401 14.2975H63.6269V8.60557Z"
      fill="#004A93"
    />
    <path
      d="M91.4764 17.4821C94.3223 17.4821 95.9487 19.515 95.9487 21.8865C95.9487 24.3259 94.3223 26.291 91.4764 26.291C88.6305 26.291 87.0043 24.3938 87.0043 21.8865C87.0043 19.515 88.6305 17.4821 91.4764 17.4821ZM83.5485 6.23398L86.3945 9.41872L91.8831 5.21761L97.3716 9.41872L100.285 6.23398L91.8831 0L83.5485 6.23398ZM102.657 12.1291H95.6775V14.0264C94.2546 12.3323 91.8831 11.4515 89.6469 11.4515C83.8872 11.4515 79.8216 16.2624 79.8216 21.8865C79.8216 27.5107 83.8872 32.3216 89.7147 32.3216C92.0186 32.3216 94.3902 31.4408 95.6098 29.4757H95.6775V31.6441H102.657V12.1291Z"
      fill="#004A93"
    />
    <path d="M113.092 31.7118H106.113V0H113.092V31.7118Z" fill="#004A93" />
    <path
      d="M127.051 17.4822C129.897 17.4822 131.523 19.5149 131.523 21.8866C131.523 24.326 129.897 26.2911 127.051 26.2911C124.205 26.2911 122.579 24.3937 122.579 21.8866C122.646 19.5149 124.273 17.4822 127.051 17.4822ZM127.051 11.4515C120.749 11.4515 115.26 15.2461 115.26 21.8866C115.26 28.5271 120.817 32.3217 127.051 32.3217C133.352 32.3217 138.841 28.5271 138.841 21.8866C138.841 15.2461 133.352 11.4515 127.051 11.4515Z"
      fill="#004A93"
    />
    <path
      d="M147.989 31.7119H141.009V12.1292H147.989V31.7119ZM148.395 4.60784C148.395 6.77608 146.634 8.47012 144.465 8.47012C142.297 8.47012 140.603 6.7084 140.603 4.60784C140.603 2.43946 142.364 0.677612 144.465 0.677612C146.701 0.677612 148.395 2.43946 148.395 4.60784Z"
      fill="#004A93"
    />
    <path
      d="M164.387 17.1434C163.302 16.6013 161.811 16.1947 160.659 16.1947C159.847 16.1947 158.423 16.5335 158.423 17.5499C158.423 18.9051 160.659 19.1084 161.608 19.2439C164.861 19.9216 167.3 21.4123 167.3 25.0714C167.3 30.2212 162.624 32.3894 158.017 32.3894C155.239 32.3894 152.46 31.5764 150.157 30.1533L152.664 25.2746C154.087 26.2911 156.458 27.2396 158.153 27.2396C159.034 27.2396 160.389 26.8331 160.389 25.7489C160.389 24.1905 158.153 24.1227 155.985 23.5807C153.816 23.0385 151.648 21.9543 151.648 18.4309C151.648 13.6198 155.985 11.4515 160.32 11.4515C162.557 11.4515 164.725 11.7904 166.758 12.7389L164.387 17.1434Z"
      fill="#004A93"
    />
    <path
      d="M175.499 19.0407C175.906 17.0078 177.6 15.9914 179.633 15.9914C181.531 15.9914 183.223 17.2111 183.562 19.0407H175.499ZM190 22.3609C190 15.7204 186.069 11.5192 179.294 11.5192C172.925 11.5192 168.452 15.4493 168.452 21.9543C168.452 28.7304 173.331 32.3895 179.768 32.3895C184.241 32.3895 188.645 30.2888 189.73 25.6812H183.02C182.276 26.9685 181.191 27.4429 179.7 27.4429C176.854 27.4429 175.363 25.8844 175.363 23.1063H190V22.3609Z"
      fill="#004A93"
    />
    <path
      d="M30.2211 27.3075L36.7939 33.8803L22.903 47.8389L0 24.9359L22.903 2.03284L43.4344 22.5643H53.1241V31.6442H39.03L22.903 15.5172L13.3488 25.0714L22.903 34.5579L30.2211 27.3075Z"
      fill="#004A93"
    />
  </svg>
);

BaloiseLogoFr.displayName = 'BaloiseLogoFr';

export default BaloiseLogoFr;
