import { range } from '@scout24ch/fs24-utils';
import { DamageType } from '../enums';
import { Damage, DamageError } from '../types';

export const getDamageType = (type: DamageType): 'privateLiability' | 'household' => {
  return type === DamageType.PrivateLiability ? 'privateLiability' : 'household';
};

export const createDamagesArray = (numberOfDamages: number, type: DamageType): Damage[] => {
  return range(numberOfDamages, () => ({
    reason: null,
    year: null,
    month: null,
    amount: null,
    type: getDamageType(type),
  }));
};

export const createDamagesErrorArray = (numberOfDamages: number): DamageError[] => {
  return range(numberOfDamages, () => ({
    reason: false,
    year: false,
    month: false,
    amount: false,
    type: false,
  }));
};
