import * as React from 'react';
import ErrorPage from '../ErrorPage';
import { captureException } from '../../utils/sentry';

export interface ErrorBoundaryProps {
  children: any;
}

export interface ErrorBoundaryState {
  readonly hasError?: boolean;
  readonly error?: Error;
}

/**
 * Catches possible errors during the render/reconcililation
 * tasks of react, can be used for catching errors of any kind.
 * @author Jan Biasi <jan.biasi(@)namics.com>
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static displayName = 'ErrorBoundary';

  public state: ErrorBoundaryState = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error) {
    captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';
