// generator runtime
import 'regenerator-runtime/runtime';
// attach interceptors
import '../api/setup';
import { all } from 'redux-saga/effects';
import { watchInsuranceSum, watchMobiliarInsuranceSum } from './insuranceSumSaga/insuranceSumSaga';
import { watchTranslations } from './languageSaga/languageSaga';

// Root sagas
// Single entry point to start all sagas at once
export function* rootSaga() {
  yield all([
    // add your saga watchers here, see as reference:
    watchInsuranceSum(),
    watchMobiliarInsuranceSum(),
    watchTranslations(),
    // https://github.xmedia.ch/Financescout24/credit-frontend/blob/master/src/sagas/rootSaga.ts
  ]);
}
