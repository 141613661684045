import { SingleChoiceSelectionOption } from '../../components/QuestionTypes/SingleChoiceSelection/SingleChoiceSelection';
import { Field } from '../../enums';
import { City, Damage } from '../../types';
import * as actions from './privateLiabilityinquiry.actionTypes';
import { PrivateLiabilityFields } from './types';

export const setField = (
  field: string,
  newValue: SingleChoiceSelectionOption | City | string[] | Damage | Damage[],
) => {
  return { type: actions.SET_FIELD, newValue, field } as const;
};

export const setPrivateLiabilityInquiryFields = (fields: PrivateLiabilityFields) => {
  return { type: actions.SET_PRIVATE_LIABILITY_INQUIRY_FIELDS, fields } as const;
};

export const setArrayField = (field: Field, newValue: Damage | string, index: number) => {
  return {
    type: actions.SET_ARRAY_FIELD,
    newValue,
    index,
    field,
  } as const;
};

export type ActionType = ReturnType<typeof setField | typeof setPrivateLiabilityInquiryFields | typeof setArrayField>;
