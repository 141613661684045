export enum MobiliarActionType {
  GO_TO_INPUT_INSURANCE_PACKAGE = 'go_to_input_insurance_package',
  GO_TO_INPUT_PERSONAL_INFO = 'go_to_input_personal_info',
  GO_TO_ONLINE_REGISTER = 'go_to_online_register',
  REQUEST_INSURANCE_OFFER = 'request_insurance_offer',
  PURCHASE_ONLINE_INSURANCE = 'purchase_online_insurance',
  COMPLETE_PROPERTY_INFO = 'complete_property_info',
  COMPLETE_INTERIOR_INFO = 'complete_interior_info',
  COMPLETE_INSURANCE_CHOSEN = 'complete_insurance_chosen',
  COMPLETE_PERSONAL_INFO = 'complete_personal_info',
  COMPLETE_LAST_INSURANCE_INFO = 'complete_last_insurance_info',
}
