export enum Canton {
  AG = 'AG',
  AI = 'AI',
  AR = 'AR',
  BE = 'BE',
  BL = 'BL',
  BS = 'BS',
  FR = 'FR',
  GE = 'GE',
  GL = 'GL',
  GR = 'GR',
  JU = 'JU',
  LU = 'LU',
  NE = 'NE',
  NW = 'NW',
  OW = 'OW',
  SG = 'SG',
  SH = 'SH',
  SO = 'SO',
  SZ = 'SZ',
  TG = 'TG',
  TI = 'TI',
  UR = 'UR',
  VD = 'VD',
  VS = 'VS',
  ZG = 'ZG',
  ZH = 'ZH',
}
