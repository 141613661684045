import { call, put, takeEvery, select } from 'redux-saga/effects';
import { FETCH_LANGUAGE } from '../../state/language/language.actionTypes';
import { setLanguage } from '../../state/language/language.actionCreators';
import { getCurrentLanguageCode } from '../../state/language/language.selectors';
import { fetchTranslations } from '../../api/translations';
import { captureException } from '../../utils/sentry';

export function* workFetcTranslations() {
  try {
    const languageCode = yield select(getCurrentLanguageCode);
    const translations = yield call(fetchTranslations, languageCode);
    yield put(setLanguage(languageCode, translations));
  } catch (err) {
    captureException(err);
  }
}

export function* watchTranslations() {
  try {
    yield takeEvery(FETCH_LANGUAGE, workFetcTranslations);
  } catch (err) {
    captureException(err);
  }
}
