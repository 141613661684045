import { useSelector } from 'react-redux';
import { getTranslations } from '../state/language/language.selectors';
import { TranslationKey } from '../types';
import { interpolate } from '../utils';

export const useTranslations = () => {
  const translations = useSelector(getTranslations);

  return (translationKey: TranslationKey, replacements?: Record<string, string>) => {
    return interpolate(translations[translationKey], replacements);
  };
};
