export const scrollTo = (id: string) => {
  setTimeout(() => {
    const nextField = document.querySelector(`[data-scroll=${id}]`);
    if (nextField) {
      nextField.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      nextField.removeAttribute('data-scroll');
    }
  }, 200);
};

export const scrollToFirstErrorField = (delay = 300) => {
  setTimeout(() => {
    const element = document.querySelector('.chakra-form__error-message');

    element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, delay);
};

export const disableScrolling = () => {
  const scrollingCheckpoints = document.querySelectorAll(`[data-scroll]`);
  scrollingCheckpoints.forEach((node) => node.removeAttribute('data-scroll'));
};
