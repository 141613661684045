import * as actions from './requestedOffers.actionTypes';
import { ActionType } from './requestedOffers.actionCreators';

export type RequestedOffersState = Readonly<number[]>;

export const initialState: RequestedOffersState = [];

const requestedOffersReducer = (
  state: RequestedOffersState = initialState,
  action: ActionType,
): RequestedOffersState => {
  switch (action.type) {
    case actions.ADD_REQUESTED_OFFER_ID:
      return [...state, action.id];
    case actions.ADD_REQUESTED_OFFER_IDS:
      return [...state, ...action.ids];

    default:
      return state;
  }
};

export default requestedOffersReducer;
