import axios, { AxiosRequestConfig } from 'axios';
import { cloudFlareInterceptor } from '../cloudFlare/cloudFlareInterceptor';
import { environment } from '../../common/environment';

const isProd = process.env.NODE_ENV === 'production';
const isTest = process.env.NODE_ENV === 'test';

const apiBaseURL =
  isProd || isTest
    ? `${environment.HOUSEHOLD_APP_HOST}/api/household-service/api/v1`
    : 'https://household-insurance-frontend.int.financescout24.ch/api/household-service/api/v1';

const config: AxiosRequestConfig = {
  baseURL: apiBaseURL,
};

if (isProd) {
  config.withCredentials = true;
}

const insuranceApiClient = axios.create(config);

insuranceApiClient.interceptors.request.use(cloudFlareInterceptor);

export { insuranceApiClient };
