import { SET_LOADING } from './loading.actionTypes';
import { ActionType } from './loading.actionCreators';

export interface InternalLoadingState {
  isLoading: boolean;
}

export type LoadingState = Readonly<InternalLoadingState>;

export const initialState: LoadingState = {
  isLoading: false,
};

const loadingReducer = (state: LoadingState = initialState, action: ActionType): LoadingState => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default loadingReducer;
