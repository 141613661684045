/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const HelvetiaLogo = (props: IconProps) => (
  <svg className={`a-icon a-icon--helvetia-logo ${props.className || ''}`} width="1em" height="1em" {...props}>
    <path
      fill="#181716"
      d="M6.015 9.5v10.053h.066c1.39-1.85 3.075-2.711 5.424-2.711 4.295 0 6.379 2.844 6.379 7.143v10.381h-6.016v-8.694c0-1.985-.298-4.032-2.712-4.032-2.379 0-3.141 2.047-3.141 4.032v8.694H0V9.5h6.015zM38.282 26.896H25.62c0 2.444 1.291 3.768 3.77 3.768 1.29 0 2.214-.431 2.876-1.553h5.785c-.96 4-4.761 5.818-8.629 5.818-5.62 0-9.819-3.173-9.819-9.025 0-5.655 3.867-9.061 9.389-9.061 5.885 0 9.289 3.638 9.289 9.424v.629zm-5.586-3.473c-.299-1.621-1.786-2.68-3.406-2.68-1.752 0-3.206.928-3.572 2.68h6.978zM46.05 34.366h-6.015V9.5h6.015v24.866zM57.465 26.103l4.034-8.698h6.745l-8.861 16.962h-3.835l-8.893-16.962h6.744l4.066 8.698zM85.437 26.896H72.775c0 2.444 1.289 3.768 3.77 3.768 1.289 0 2.215-.431 2.875-1.553h5.785c-.958 4-4.761 5.818-8.626 5.818-5.62 0-9.819-3.173-9.819-9.025 0-5.655 3.869-9.061 9.388-9.061 5.884 0 9.29 3.638 9.29 9.424v.629zm-5.588-3.473c-.296-1.621-1.784-2.68-3.403-2.68-1.753 0-3.208.928-3.571 2.68h6.974zM94.31 34.366h-6.016v-12h-1.95v-4.962h1.95v-5.09h6.016v5.09h3.405v4.962H94.31v12zM105.237 12.05a3.384 3.384 0 0 1-3.371 3.371 3.386 3.386 0 0 1-3.372-3.371 3.387 3.387 0 0 1 3.372-3.372 3.383 3.383 0 0 1 3.371 3.372zm-.362 22.316h-6.019V17.404h6.019v16.962zM126.397 34.366h-6.02V32.48h-.064c-1.06 1.688-3.141 2.448-5.124 2.448-5.026 0-8.563-4.199-8.563-9.06 0-4.862 3.472-9.027 8.498-9.027 1.948 0 3.997.728 5.254 2.216v-1.654h6.02v16.963zm-13.554-8.463c0 2.148 1.42 3.8 3.866 3.8s3.868-1.651 3.868-3.8c0-2.083-1.422-3.836-3.868-3.836s-3.866 1.753-3.866 3.836z"
    />
    <path fill="#563B6C" d="M147.832 27.238l-7.721 1.983 6-13.866 7.749-1.848z" />
    <path fill="#71518C" d="M145.726 1.699L153.506 0l3.132 7.181-7.759 1.786z" />
    <path fill="#8761A8" d="M134.379 28.239l11.347-26.54 3.153 7.268-8.768 20.254z" />
    <g>
      <path fill="#118289" d="M140.111 29.221l7.721-1.983 12.938 2.16-7.701 2.04z" />
      <path fill="#46A8B3" d="M156.236 38.762l-24.472-4.396 2.615-6.127 18.69 3.199z" />
    </g>
    <g>
      <path fill="#9F1717" d="M148.879 8.967l7.759-1.786 13.301 30.508-7.662 2.157z" />
      <path fill="#C21924" d="M146.111 15.355l2.768-6.388 13.398 30.879-6.041-1.084z" />
    </g>
  </svg>
);

HelvetiaLogo.displayName = 'HelvetiaLogo';

export default HelvetiaLogo;
