import { PrivateLiabilityPremiumDto } from '../../types/Responses';
import { ActionType } from './selectedPrivateLiabilityPremiums.actionCreators';
import * as actions from './selectedPrivateLiabilityPremiums.actionTypes';

export type SelectedPrivateLiabilityPremiumsState = readonly PrivateLiabilityPremiumDto[];

export const initialState: SelectedPrivateLiabilityPremiumsState = [];

const selectedPremiumsReducer = (
  state: SelectedPrivateLiabilityPremiumsState = initialState,
  action: ActionType,
): SelectedPrivateLiabilityPremiumsState => {
  switch (action.type) {
    case actions.SET_SELECTED_PRIVATE_LIABILITY_PREMIUMS:
      return [...action.premiums];
    case actions.ADD_SELECTED_PRIVATE_LIABILITY_PREMIUM:
      return [...state, action.premium];
    case actions.REMOVE_SELECTED_PRIVATE_LIABILITY_PREMIUM:
      return state.filter((premium) => premium.productId !== action.premiumId);
    case actions.CLEAR_SELECTED_PRIVATE_LIABILITY_PREMIUMS:
      return [];
    default:
      return state;
  }
};

export default selectedPremiumsReducer;
