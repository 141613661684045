import { useRouter } from 'next/router';
import { ProductType } from '../enums';

export function useProductType(): ProductType {
  const { pathname } = useRouter();

  if (pathname.startsWith('/mobiliar')) {
    return ProductType.Mobiliar;
  }

  if (pathname.startsWith('/private-liability')) {
    return ProductType.PrivateLiability;
  }

  return ProductType.Household;
}
