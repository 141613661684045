export enum DateError {
  DateOfBirthMissing = 'dateOfBirthMissing',
  DateMissing = 'dateMissing',
  DateOfBirthOver100 = 'dateOfBirthOver100',
  DateOfBirthUnder18 = 'dateOfBirthUnder18',
  DateOfBirthOver18 = 'dateOfBirthOver18',
  InvalidDateFormat = 'invalidDateFormat',
  InsuranceStartDateMissing = 'insuranceStartDateMissing',
  DateOverOneYearInTheFuture = 'dateOverOneYearInTheFuture',
  DateToDayOrInThePast = 'dateTodayOrInThePast',
  DateNotFilled = 'dateNotFilled',
  GeneralDateError = 'generalDateError',
}
