import * as Sentry from '@sentry/node';
import { NodeOptions } from '@sentry/node';
import { BrowserOptions } from '@sentry/browser';
import { Express } from 'express';
import { RewriteFrames, ExtraErrorData } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { Options, SamplingContext, Integration } from '@sentry/types';
import {
  getGlobal,
  shouldIgnoreException,
  fingerprintException,
  exceptionUrlFilter,
  ExceptionFilter,
} from '@scout24ch/fs24-utils';
import { environment } from '../common/environment';

declare const URL: any;

type SentryOptions = Options | NodeOptions | BrowserOptions;

const EXTRA_DATA_DEPTH = 10;

const TRACES_SAMPLE_RATE = parseFloat(environment.SENTRY_TRACES_SAMPLE_RATE);

export const init = ({ app }: { app?: Express } = {}) => {
  if (!environment.SENTRY_DSN) {
    return;
  }

  let tracesSampler: (context: SamplingContext) => number | boolean;
  const integrations: Integration[] = [new ExtraErrorData({ depth: EXTRA_DATA_DEPTH })];

  if (process.env.NEXT_IS_SERVER === 'true') {
    const excludedTraces = ['/static/', '/_next/', '/api/v1/health'];

    tracesSampler = (context) => {
      try {
        const { pathname } = new URL(context.request?.url || 'http://localhost/');

        if (excludedTraces.some((path) => pathname.startsWith(path))) {
          return 0;
        }

        if (context.parentSampled !== undefined) {
          return context.parentSampled;
        }

        return TRACES_SAMPLE_RATE;
      } catch {
        return 0;
      }
    };

    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename
            // Normalizing cwd to match in case we ever move the directory within
            // the images.
            ?.replace(process.cwd() || '', 'app:///')
            // Matching filename from server to the from the browser build,
            // as the browser builds into _next.
            .replace('.next', '_next');

          return frame;
        },
      }),
    );

    integrations.push(new Sentry.Integrations.Http({ tracing: true }));
    integrations.push(new TracingIntegrations.Express({ app }));
  } else {
    tracesSampler = (context) => context.parentSampled ?? TRACES_SAMPLE_RATE;

    integrations.push(
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', 'www.financescout24.ch', /^\//],
        beforeNavigate: (context) => {
          const location: { pathname: string } = (getGlobal() as any).location;

          return {
            ...context,
            name: location.pathname
              .replace(/^\/(?:de|fr|it|en)/, '')
              .replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi, '/<uuid>')
              .replace(/\/[a-f0-9]{32}/gi, '/<hash>')
              .replace(/\/\d+/g, '/<digits>'),
          };
        },
      }),
    );
  }

  const enabled = environment.SENTRY_ENABLED === 'true';

  const ignoreErrorsList: ExceptionFilter[] = [
    // Error thrown by redirect in case vehicle was not found from as24
    (error: Error) => error && error.message === 'vehicle-error',
    // GTM errors - https://sentry.io/share/issue/0e3f8d7b13c74757bb2c4c7f5c4ba899/
    /Can't find variable: ttq/,
    /ttq is not defined/,
    /Failed to fetch/,

    // https://financescout24.sentry.io/issues/5035358483/?project=5648835&query=is%3Aunresolved+is%3Afor_review+assigned_or_suggested%3A%5Bme%2C+my_teams%2C+none%5D&referrer=issue-stream&statsPeriod=14d&stream_index=0
    /Request failed with status code 400/,
  ];

  const options: SentryOptions = {
    environment: environment.ENVIRONMENT,
    integrations,
    normalizeDepth: EXTRA_DATA_DEPTH + 1,
    tracesSampler,
    dsn: environment.SENTRY_DSN,
    denyUrls: exceptionUrlFilter(),
    release: environment.VERSION === 'dev' ? undefined : environment.GITCOMMIT || environment.VERSION,
    beforeSend(event, hint) {
      if (shouldIgnoreException(hint?.originalException, ignoreErrorsList)) {
        return null;
      }

      fingerprintException(event, hint?.originalException);
      if (environment.SENTRY_LOG_TO_CONSOLE === 'true') {
        // eslint-disable-next-line no-console
        console.error(hint?.originalException || hint?.syntheticException);
      }

      return enabled ? event : null;
    },
  };

  Sentry.init(options);

  Sentry.configureScope((scope) => {
    scope.setTag('SSR', process.env.NEXT_IS_SERVER === 'true');
  });
};

export const captureException: typeof Sentry.captureException = environment.SENTRY_DSN
  ? Sentry.captureException
  : // eslint-disable-next-line no-console
    (err) => (console.error(err), '');

export const configureScope = Sentry.configureScope;
export const withScope = Sentry.withScope;
