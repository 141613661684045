import * as actions from './alerts.actionTypes';
import { ActionType } from './alerts.actionCreators';
import { AlertType } from '../../enums';
import { Alert } from './types';

export type AlertsState = Readonly<Alert>;

const initialState: AlertsState = {
  message: '',
  type: AlertType.None,
};

const alertsReducer = (state: AlertsState = initialState, action: ActionType): AlertsState => {
  switch (action.type) {
    case actions.SET_MULTIPLE_OFFERS_REQUESTED_ALERT:
      return { ...action.newAlertValues };
    case actions.CLEAR_MULTIPLE_OFFERS_REQUESTED_ALERT:
      return { type: AlertType.None, message: '' };
    default:
      return state;
  }
};

export default alertsReducer;
