import { InsuranceCompany, Language, ResidencePermit } from '../enums';
import { TranslationKey } from '../types';
import { countryCodes } from '../types/CountryCodes';
import { useTranslations } from './useTranslations';

export const useDropDownOptions = () => {
  const t = useTranslations();

  const residencePermitOptions = [
    { value: ResidencePermit.B, label: 'B' },
    { value: ResidencePermit.C, label: 'C' },
    { value: ResidencePermit.L, label: 'L' },
  ];

  const genderOptions = [
    { value: 'mr', label: t('genderMale') },
    { value: 'ms', label: t('genderFemale') },
  ];

  const getLanguageOptions = (insuranceCompany: InsuranceCompany) => {
    if (insuranceCompany.toLowerCase() === InsuranceCompany.Simpego) {
      return [
        { value: Language.De, label: t('deLabel') },
        { value: Language.Fr, label: t('frLabel') },
      ];
    }
    return [
      { value: Language.De, label: t('deLabel') },
      { value: Language.Fr, label: t('frLabel') },
      { value: Language.It, label: t('itLabel') },
      { value: Language.En, label: t('enLabel') },
    ];
  };

  const countryOptions = countryCodes.map((c) => ({
    label: t(c as TranslationKey),
    value: c,
  }));
  return { residencePermitOptions, genderOptions, getLanguageOptions, countryOptions };
};
