import { QuoteDto } from '../../types/Responses';
import { InsuranceCompany, SortingOptions } from '../../enums';
import { asInt, sorted } from '../../utils';
import { RootState } from '../rootReducer';

type State = Pick<RootState, 'premiums'>;

export const getPremiums = (state: State) => {
  return state.premiums;
};

export const getPremiumsByIds = (state: State, ids: string[]) => {
  return state.premiums.filter((premium) => ids.includes(String(premium.productId)));
};

export const getPremiumWithHighestMatchingScore = (state: State): QuoteDto => {
  return sorted(state.premiums, SortingOptions.MatchingScore)[0];
};

export const getPremiumWithLowestPrice = (state: State): QuoteDto => {
  return sorted(state.premiums, SortingOptions.Price)[0];
};

export const getPremiumById = (state: State, id: string) => {
  return state.premiums.find((premium) => premium.productId === asInt(id));
};

export const getNumberOfPremiums = (state: State) => {
  return state.premiums.length;
};

export const getSameCompanyPremiums = (state: State, companyName: string, productId: number) => {
  return state.premiums.filter(
    (premium) =>
      premium.productId !== productId &&
      premium.insuranceCompanyInfo.companyName.toLowerCase() === companyName.toLowerCase(),
  );
};

export const getOtherCompanyPremiums = (
  state: State,
  companyName: string,
  productId: number,
  offerNationality: string,
) => {
  return state.premiums
    .filter((premium) => premium.productId !== productId)
    .filter((premium) => premium.insuranceCompanyInfo.companyName.toLowerCase() !== companyName.toLowerCase())
    .filter(
      (premium) =>
        premium.insuranceCompanyInfo.companyName.toLowerCase() !== InsuranceCompany.Simpego ||
        offerNationality === 'ch',
    );
};
