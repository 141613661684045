import * as React from 'react';
import { useRouter } from 'next/router';
import { FooterWidget } from '@scout24ch/fs24-widgets';
import { HeaderWidgetRenderer, MobiliarHeader } from '../WidgetRenderer';
import { useSelector } from '../../state/rootReducer';
import { getSelectedPremiums } from '../../state/selectedPremiums/selectedPremiums.selectors';
import { getSelectedPrivateLiabilityPremiums } from '../../state/selectedPrivateLiabilityPremiums/selectedPrivateLiabilityPremiums.selectors';
import { TemplateLayout } from '../TemplateLayout';
import { useIsMobiliar } from '../../hooks';

export interface HouseholdPageLayoutProps {
  children: React.ReactNode;
}

export const HouseholdPageLayout: React.FC<HouseholdPageLayoutProps> = ({ children }) => {
  const router = useRouter();
  const selectedPremiums = useSelector(getSelectedPremiums);
  const privateLiabilitySelectedPremiums = useSelector(getSelectedPrivateLiabilityPremiums);
  const pathname = router?.pathname;
  const isMobiliar = useIsMobiliar();

  const isFooterHidden = () => {
    return (
      pathname.startsWith('/inquiry') ||
      pathname.startsWith('/private-liability/inquiry') ||
      (pathname.startsWith('private-liability/results') && privateLiabilitySelectedPremiums.length > 0) ||
      (pathname.startsWith('/result') && selectedPremiums.length > 0) ||
      isMobiliar
    );
  };

  return (
    <TemplateLayout
      header={isMobiliar ? <MobiliarHeader /> : <HeaderWidgetRenderer />}
      footer={isFooterHidden() ? null : <FooterWidget />}
    >
      {children}
    </TemplateLayout>
  );
};

HouseholdPageLayout.displayName = 'HouseholdPageLayout';
