import { RootState } from '../rootReducer';

type State = Pick<RootState, 'language'>;

export const getCurrentLanguageCode = (state: State) => {
  return state.language.languageCode;
};

export const getTranslations = (state: State) => {
  return state.language.translations.householdInsurance.common;
};
