import * as actions from './privateLiabilityPremiums.actionTypes';
import { ActionType } from './privateLiabilityPremiums.actionCreators';
import { PrivateLiabilityPremiumDto } from '../../types/Responses';

export type PrivateLiabilityPremiumsState = Readonly<PrivateLiabilityPremiumDto[]>;

export const initialState: PrivateLiabilityPremiumsState = [];

const privateLiabilityPremiumsReducer = (
  state: PrivateLiabilityPremiumsState = initialState,
  action: ActionType,
): PrivateLiabilityPremiumsState => {
  switch (action.type) {
    case actions.ADD_PRIVATE_LIABILITY_PREMIUMS:
      return action.newValue;
    default:
      return state;
  }
};

export default privateLiabilityPremiumsReducer;
