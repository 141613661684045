import * as actions from './privateLiabilityinquiryError.actionTypes';
import { ActionType } from './privateLiabilityinquiryError.actionCreators';
import { Field } from '../../enums';
import { PrivateLiabilityInputFieldsError } from './types';

export type PrivateLiabilityInquiryErrorState = Readonly<PrivateLiabilityInputFieldsError>;

export const initialState: PrivateLiabilityInquiryErrorState = {
  [Field.PossessionType]: false,
  [Field.HouseholdType]: false,
  [Field.GrossNegligence]: false,
  [Field.NonOwnerDriverInsurance]: false,
  [Field.DateOfBirth]: null,
  [Field.IsDogOwner]: false,
  [Field.NumberOfDogs]: false,
  [Field.NumberOfInsurees]: false,
  [Field.NumberOfChildren]: false,
  [Field.ChildrenDatesOfBirth]: [],
  [Field.City]: { postalCode: null, name: false, canton: false },
  [Field.InsuranceStartDate]: null,
  [Field.CompensationForPrivateLiability]: false,
  [Field.ContractTerminatedByPreviousInsurer]: false,
  [Field.NumberOfPrivateLiabilityCompensations]: false,
  [Field.PrivateLiabilityDamages]: [],
  [Field.PreviousInsurer]: false,
  [Field.ReasonForCancellation]: false,
  [Field.EmailAddress]: null,
  [Field.DataProtectionAccepted]: false,
};

const privateLiabilityInquiryErrorReducer = (
  state: PrivateLiabilityInquiryErrorState = initialState,
  action: ActionType,
): PrivateLiabilityInquiryErrorState => {
  switch (action.type) {
    case actions.SET_FIELD_ERROR:
      return { ...state, [action.field]: action.error };
    case actions.SET_ALL_ERRORS:
      return { ...action.errors };
    case actions.SET_POSTAL_CODE_ERROR:
      return { ...state, [Field.City]: { ...state.city, postalCode: action.error } };
    case actions.SET_CHILD_BIRTH_DATE_ERROR:
      const newErrors = state.childrenDatesOfBirth;
      newErrors[action.index] = action.error;
      return { ...state, [Field.ChildrenDatesOfBirth]: newErrors };
    case actions.SET_DAMAGE_AMOUNT_ERROR_PRIVATE_LIABILITY:
      const newErrorArr = [...state.privateLiabilityDamages];
      newErrorArr[action.index] = { ...newErrorArr[action.index], amount: action.error };
      return {
        ...state,
        [Field.PrivateLiabilityDamages]: newErrorArr,
      };
    default:
      return state;
  }
};

export default privateLiabilityInquiryErrorReducer;
