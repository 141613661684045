import { Language } from '../../enums';
import * as actions from './language.actionTypes';

export const updateLanguage = (languageCode: Language) => {
  return {
    type: actions.UPDATE_LANGUAGE,
    languageCode,
  } as const;
};

export const setLanguage = (languageCode: Language, translations: object) => {
  return {
    type: actions.SET_LANGUAGE,
    languageCode,
    translations,
  } as const;
};

export const fetchLanguage = () => {
  return { type: actions.FETCH_LANGUAGE };
};

export type ActionType = ReturnType<typeof setLanguage | typeof updateLanguage | typeof fetchLanguage>;
