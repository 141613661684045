import { SingleChoiceSelectionOption } from '../../components/QuestionTypes/SingleChoiceSelection/SingleChoiceSelection';
import { DamageType, Field } from '../../enums';
import { City, Damage } from '../../types';
import { getDamageType } from '../../utils/createDamagesArray';
import * as actions from './inquiry.actionTypes';

export const setHouseholdInquiryField = (
  field: Field,
  newValue: SingleChoiceSelectionOption | City | string[] | Damage | Damage[],
) => {
  return { type: actions.SET_HOUSEHOLD_FIELD, newValue, field } as const;
};

export const setInitialDamagesArray = (numberOfDamages: number, damagesType: DamageType) => {
  const initialArray = Array.from({ length: numberOfDamages }, () => ({
    reason: null,
    year: null,
    month: null,
    amount: null,
    type: getDamageType(damagesType),
  }));
  return {
    type: actions.SET_INITIAL_DAMAGES_ARRAY,
    initialArray,
    damagesType,
  } as const;
};

export const setDamageDetailField = (newValue: string, damageType: string, field: string, index: number) => {
  return {
    type: actions.SET_DAMAGE_DETAIL,
    newValue,
    damageType,
    field,
    index,
  } as const;
};

export const fetchInsuranceSum = () => {
  return { type: actions.FETCH_INSURANCE_SUM } as const;
};

export const setInquiryFields = (newInquiry: any) => {
  return { type: actions.SET_INQUIRY_FIELDS, newInquiry } as const;
};

export const setQuickAdjustFields = (newValues: any) => {
  return { type: actions.SET_QUICK_ADJUST_FIELDS, newValues } as const;
};

export const updateChildrenDatesOfBirth = (newNumberOfChildren: number) => {
  return {
    type: actions.UPDATE_CHILDREN_DATES_OF_BIRTH_ARRAY_LENGTH,
    newNumberOfChildren,
  } as const;
};

export const setChildDateOfBirth = (dateOfBirth: string, index: number) =>
  ({
    type: actions.SET_CHILD_DATE_OF_BIRTH,
    dateOfBirth,
    index,
  } as const);

export type ActionType = ReturnType<
  | typeof setInitialDamagesArray
  | typeof setDamageDetailField
  | typeof fetchInsuranceSum
  | typeof setInquiryFields
  | typeof setQuickAdjustFields
  | typeof setChildDateOfBirth
  | typeof setHouseholdInquiryField
  | typeof updateChildrenDatesOfBirth
>;
