import { SingleChoiceSelectionOption } from '../components/QuestionTypes';
import { Field, ProductType } from '../enums';
import { setHouseholdInquiryField } from '../state/inquiry/inquiry.actionCreators';
import { setMobiliarInquiryField } from '../state/mobiliar/inquiry/inquiry.actionCreators';
import { setField } from '../state/privateLiabilityInquiry/privateLiabilityinquiry.actionCreators';
import { useDispatch } from '../state/rootReducer';
import { City, Damage } from '../types';
import { scrollTo } from '../utils';
import { useProductType } from './useProductType';

const getAction = (productType: ProductType) => {
  if (productType === ProductType.Household) {
    return setHouseholdInquiryField;
  }
  if (productType === ProductType.Mobiliar) {
    return setMobiliarInquiryField;
  }

  return setField;
};

export const useHandleFieldChange = () => {
  const productType = useProductType();
  const dispatch = useDispatch();
  const handleFieldChange = (
    field: Field,
    newValue: SingleChoiceSelectionOption | City | string[] | Damage | Damage[],
    nextField?: Field | string,
  ) => {
    const action = getAction(productType);

    dispatch(action(field, newValue));
    if (nextField) {
      scrollTo(nextField);
    }
  };

  return handleFieldChange;
};
