import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas/rootSaga';
import { rootReducer, RootState } from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  (typeof window != 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const initStore = (initialState: Partial<RootState> = {}) => {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

  // enable root saga for taking actions first (run only on client)
  if (typeof window !== 'undefined') {
    sagaMiddleware.run(rootSaga);
  }

  return store;
};
