import { useOnMount } from '@scout24ch/fs24-hooks';
import * as React from 'react';
import { GoogleTagManagerProvider, GTMOptions, GUID_REGEX, useGoogleTagManager } from '@scout24ch/fs24-gtm-react';
import { castArray, cookieStorage } from '@scout24ch/fs24-utils';
import { useRouter } from 'next/router';
import { environment, isProd } from '../../common/environment';
import { ReferrerPage } from 'enums';
import { COOKIE_REFERRER_PAGE } from 'common/constants';
import { useDispatch, useSelector } from 'state/rootReducer';
import { setReferrerPage } from 'state/mobiliar/inquiry/inquiry.actionCreators';
import { getReferrerPage } from 'state/mobiliar/inquiry/inquiry.selectors';
import { useLocale, useUser } from '../../hooks';

// The first match is used in pageviews
//the order needs to be like this
const evppMapping = new Map([
  [`/private-liability/inquiry`, 'private-liability/comparison/1-object_data'],
  [`/private-liability/results`, 'private-liability/comparison/5-resultlist'],
  [`/private-liability/details/${GUID_REGEX}/.*(%2C|,).*`, 'private-liability/comparison/7-details-multiple-offer'],
  [`/private-liability/details/${GUID_REGEX}/`, 'private-liability/comparison/6-details-single-offer'],
  [`/private-liability/offer-completion`, 'private-liability/comparison/9-offer-confirmation'],
  [`/private-liability/offer`, 'private-liability/comparison/8-request-offer'],
  [`/private-liability/direct-deal-completion`, 'private-liability/comparison/11-direct-confirmation'],
  [`/private-liability/direct-deal`, 'private-liability/comparison/10-direct-overview'],
  [`/inquiry`, 'household-insurance/comparison/1-personal-information'],
  [`/result`, 'household-insurance/comparison/2-resultlist'],
  [`/details/${GUID_REGEX}/`, 'household-insurance/comparison/3-details-single-offer'],
  [`/offer-completion`, 'household-insurance/comparison/5-offer-confirmation'],
  [`/offer`, 'household-insurance/comparison/4-request-offer'],
  [`/direct-deal-completion`, 'household-insurance/comparison/7-direct-confirmation'],
  [`/direct-deal`, 'household-insurance/comparison/6-direct-overview'],
]);

// On the result page, we have a custom pageView event
const skippedPageViews = ['/result'];

const getGtmEnvForPage = (referrerPage?: ReferrerPage): GTMOptions => {
  switch (referrerPage) {
    case ReferrerPage.Homegate:
      return {
        id: environment.GTM_HG_ID,
        auth: isProd ? undefined : environment.GTM_HG_AUTH,
        preview: isProd ? undefined : environment.GTM_HG_ENV,
        beagleEnv: environment.ENVIRONMENT === 'PROD' ? 'prod' : 'dev',
        evppMapping,
        skippedPageViews,
      };
    case ReferrerPage.ImmoScout24:
      return {
        id: environment.GTM_IS24_ID,
        auth: isProd ? undefined : environment.GTM_IS24_AUTH,
        preview: isProd ? undefined : environment.GTM_IS24_ENV,
        beagleEnv: environment.ENVIRONMENT === 'PROD' ? 'prod' : 'dev',
        evppMapping,
        skippedPageViews,
      };
    default:
      return {
        id: environment.GTM_ID,
        auth: isProd ? undefined : environment.GTM_AUTH,
        preview: isProd ? undefined : environment.GTM_ENV,
        beagleEnv: environment.ENVIRONMENT === 'PROD' ? 'prod' : 'dev',
        evppMapping,
        skippedPageViews,
      };
  }
};

const GtmPageView: React.FC = () => {
  const router = useRouter();
  const gtm = useGoogleTagManager();

  useOnMount(() => {
    const triggerGtmPageView = () => {
      if (!gtm.shouldSkipPageView()) {
        const category = router.pathname.includes('private-liability') ? 'Private Liability' : 'Household Insurance';

        gtm.pageView({
          primaryCategory: category,
          secondaryCategory: 'Funnel',
          productCategory: category,
        });
      }
    };

    triggerGtmPageView();

    router.events.on('routeChangeComplete', triggerGtmPageView);

    return () => router.events.off('routeChangeComplete', triggerGtmPageView);
  });

  return null;
};

export interface GoogleTagManagerProps {
  ignoredPaths?: string | string[];
  children: React.ReactNode;
}

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = (props) => {
  const { children } = props;
  const ignoredPaths = castArray(props.ignoredPaths);

  const locale = useLocale();
  const { user } = useUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const referrerPageFromRedux = useSelector(getReferrerPage);

  const ignoredRoute = React.useMemo(() => {
    return ignoredPaths.map((path) => new URL(path)).some((url) => router.asPath.startsWith(url.pathname));
  }, [router.asPath, ignoredPaths]);

  const [gtmConfig, setGTMConfig] = React.useState<GTMOptions | undefined>(undefined);

  React.useEffect(() => {
    if (router.pathname.includes('mobiliar-inquiry')) {
      const referrerFromCookie = cookieStorage.getItem(COOKIE_REFERRER_PAGE);
      const referrerFromUrl = Array.isArray(router.query.source) ? router.query.source[0] : router.query.source;
      const referrerInput = referrerFromUrl || referrerFromCookie || referrerPageFromRedux || ReferrerPage.Homegate;

      const referrer = [ReferrerPage.Homegate, ReferrerPage.ImmoScout24].includes(referrerInput as any)
        ? (referrerInput as ReferrerPage)
        : ReferrerPage.Homegate;

      cookieStorage.setItem(COOKIE_REFERRER_PAGE, referrer);
      dispatch(setReferrerPage(referrer));
      setGTMConfig(getGtmEnvForPage(referrer));
      return;
    }
    setGTMConfig(getGtmEnvForPage());
  }, [referrerPageFromRedux, router.query.source, router.pathname, dispatch]);

  if (ignoredRoute) {
    return <>{children}</>;
  }

  // GtmPageView should only be rendered once we have the correct gtm setup to prevent sending events to the wrong gtm id
  return gtmConfig ? (
    <GoogleTagManagerProvider config={gtmConfig} language={locale} userProfile={user as any}>
      <GtmPageView />
      {children}
    </GoogleTagManagerProvider>
  ) : null;
};

GoogleTagManager.displayName = 'GoogleTagManager';
