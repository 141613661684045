import * as actions from './premiums.actionTypes';
import { ActionType } from './premiums.actionCreators';
import { QuoteDto } from '../../../types/Responses';

export type PremiumsState = Readonly<QuoteDto[]>;

export const initialState: PremiumsState = [];

const premiumsReducer = (state: PremiumsState = initialState, action: ActionType): PremiumsState => {
  switch (action.type) {
    case actions.ADD_PREMIUMS:
      return action.newValue;
    default:
      return state;
  }
};

export default premiumsReducer;
