import { expand } from '@scout24ch/fs24-utils';
import axios from 'axios';
import { environment, isTest } from '../common/environment';
import { logger } from '../common/log/logger';
import { Language } from '../enums';
import { fetchConfig } from './configFetcher';

export const fetchTranslations = async (language: Language) => {
  let headers = {};

  if (isTest && typeof window === 'undefined') {
    const config = await fetchConfig();
    headers['fs24-bypass-basic-auth'] = config.FS24_BYPASS_BASIC_AUTH;
  }

  try {
    const response = await axios.get(
      `${environment.HOUSEHOLD_APP_HOST}/api/translation-service/api/v1/translations/household/${language}`,
      { headers },
    );
    return expand(response.data);
  } catch (error) {
    logger.error('Unable to fetch translations for', language, '-', error.message);
    throw error;
  }
};
