import { logger } from '../common/log/logger';
import { InsuranceSumData } from '../types';
import { insuranceApiClient } from '../lib/apiClients/insuranceApiClient';

export const requestInsuranceSum = async (insuranceSumData: InsuranceSumData) => {
  try {
    const url = `/lookup/furnishing-sum?rooms=${insuranceSumData.rooms}&insurees=${insuranceSumData.insurees}&thereofMinors=${insuranceSumData.minors}&furnishing=${insuranceSumData.furnishing}&clothing=${insuranceSumData.clothing}&electricalAppliances=${insuranceSumData.electricalAppliances}`;
    const response = await insuranceApiClient.get(url);
    return response.data;
  } catch (error) {
    logger.error('unable to fetch furnishing-sum info [api/insuranceSum]" ', error.message);
    return 0;
  }
};
