import { SortingOptions } from '../enums';

export const sortByNumberAsc = (a: number, b: number) => a - b;

export const sortByNumberDesc = (a: number, b: number) => sortByNumberAsc(b, a);

export const sortByPrice = (a: any, b: any) => sortByNumberAsc(a.premium, b.premium);

const sortByMatchingScore = (a: any, b: any) => {
  return a.matchingScore === b.matchingScore
    ? sortByNumberAsc(a.premium, b.premium)
    : sortByNumberDesc(a.matchingScore, b.matchingScore);
};

const sortCountryCodeAlphabetically = (a: any, b: any) => a.label.localeCompare(b.label);

const sorterFuncs = {
  [SortingOptions.Price]: sortByPrice,
  [SortingOptions.MatchingScore]: sortByMatchingScore,
  [SortingOptions.CountryCode]: sortCountryCodeAlphabetically,
};

export const sorted = <T>(list: readonly T[], sortingOrder?: SortingOptions): T[] => {
  const sortedList = [...list];
  sortedList.sort(sorterFuncs[sortingOrder ?? SortingOptions.MatchingScore]);
  return sortedList;
};
