import { getCurrentDeviceType, DeviceType } from '@scout24ch/fs24-utils';
import { DESKTOP_COMPARISONS, TABLET_COMPARISONS, MOBILE_COMPARISONS } from '../constants';

export const getMaxComparisons = () => {
  if (typeof window !== 'undefined') {
    const deviceType = getCurrentDeviceType();
    if (deviceType === DeviceType.DESKTOP) {
      return DESKTOP_COMPARISONS;
    }
    if (deviceType === DeviceType.TABLET) {
      return TABLET_COMPARISONS;
    }
    return MOBILE_COMPARISONS;
  }
  return DESKTOP_COMPARISONS;
};
