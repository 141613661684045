/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const BaloiseLogoDe = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--baloise-logo-de ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 190 57"
    fill="none"
    {...props}
  >
    <path
      d="M66.9581 48.3378L69.5649 41.858H72.6187L68.0009 52.5833H65.8408L61.2976 41.858H64.3513L66.9581 48.3378Z"
      fill="#004A93"
    />
    <path
      d="M74.4061 47.891C74.5551 47.1462 75.2254 46.7738 75.9702 46.7738C76.6406 46.7738 77.3109 47.2207 77.4598 47.891H74.4061ZM79.8433 49.0827C79.8433 46.6249 78.3536 45.0607 75.8212 45.0607C73.4379 45.0607 71.7993 46.5503 71.7993 48.9338C71.7993 51.466 73.5869 52.8067 76.0447 52.8067C77.6832 52.8067 79.3218 51.9874 79.7687 50.2743H77.3109C77.0129 50.7958 76.6406 50.9447 76.0447 50.9447C75.002 50.9447 74.4061 50.3489 74.4061 49.3061H79.8433V49.0827Z"
      fill="#004A93"
    />
    <path
      d="M84.1632 46.476C84.6845 45.5822 85.5783 45.2098 86.5465 45.2098H87.0679V47.6677C86.6954 47.4442 86.3975 47.4442 85.9506 47.4442C84.5355 47.4442 84.1632 48.4125 84.1632 49.6787V52.6578H81.5563V45.3588H84.1632V46.476Z"
      fill="#004A93"
    />
    <path
      d="M93.1752 47.1462C92.8029 46.9228 92.207 46.7738 91.7601 46.7738C91.4622 46.7738 90.9409 46.9228 90.9409 47.2951C90.9409 47.8165 91.7601 47.891 92.1326 47.9654C93.3242 48.1889 94.2925 48.7848 94.2925 50.1254C94.2925 52.0619 92.5049 52.8067 90.8663 52.8067C89.8237 52.8067 88.7809 52.5088 87.8871 51.9875L88.8554 50.1999C89.3768 50.5723 90.2705 50.9447 90.9409 50.9447C91.2388 50.9447 91.7601 50.7958 91.7601 50.4233C91.7601 49.8274 90.9409 49.8274 90.1215 49.604C89.3022 49.3807 88.483 49.0082 88.483 47.6675C88.483 45.88 90.1215 45.0607 91.6857 45.0607C92.5049 45.0607 93.3242 45.2096 94.069 45.5076L93.1752 47.1462Z"
      fill="#004A93"
    />
    <path
      d="M98.6124 52.5834H96.0056V45.2842H98.6124V52.5834ZM98.8358 42.454C98.8358 43.2732 98.1655 43.8691 97.3461 43.8691C96.5269 43.8691 95.8566 43.1988 95.8566 42.454C95.8566 41.6348 96.5269 40.9644 97.3461 40.9644C98.1655 40.9644 98.8358 41.6348 98.8358 42.454Z"
      fill="#004A93"
    />
    <path
      d="M106.284 47.6675C105.911 47.3696 105.465 47.1462 104.943 47.1462C103.975 47.1462 103.156 47.891 103.156 48.9338C103.156 49.9764 103.9 50.7212 104.943 50.7212C105.39 50.7212 105.911 50.5723 106.209 50.1999V52.3598C105.613 52.6578 105.018 52.8067 104.422 52.8067C102.262 52.8067 100.474 51.1681 100.474 49.0082C100.474 46.6993 102.262 45.0607 104.571 45.0607C105.167 45.0607 105.762 45.2096 106.284 45.5076V47.6675Z"
      fill="#004A93"
    />
    <path
      d="M110.902 40.741V46.1781C111.497 45.3588 112.242 44.9864 113.285 44.9864C115.147 44.9864 116.041 46.1781 116.041 48.0401V52.509H113.434V48.785C113.434 47.9656 113.285 47.0718 112.242 47.0718C111.2 47.0718 110.902 47.9656 110.902 48.785V52.509H108.295V40.741H110.902Z"
      fill="#004A93"
    />
    <path
      d="M120.361 47.891C120.51 47.1462 121.18 46.7738 121.925 46.7738C122.595 46.7738 123.265 47.2207 123.414 47.891H120.361ZM125.798 49.0827C125.798 46.6249 124.308 45.0607 121.776 45.0607C119.392 45.0607 117.754 46.5503 117.754 48.9338C117.754 51.466 119.541 52.8067 121.999 52.8067C123.638 52.8067 125.276 51.9874 125.723 50.2743H123.265C122.968 50.7958 122.595 50.9447 121.999 50.9447C120.957 50.9447 120.361 50.3489 120.361 49.3061H125.798V49.0827Z"
      fill="#004A93"
    />
    <path
      d="M130.118 46.476C130.639 45.5822 131.533 45.2098 132.501 45.2098H133.022V47.6677C132.65 47.4442 132.352 47.4442 131.905 47.4442C130.49 47.4442 130.118 48.4125 130.118 49.6787V52.6578H127.511V45.3588H130.118V46.476Z"
      fill="#004A93"
    />
    <path
      d="M137.193 45.2842V49.1571C137.193 50.0509 137.416 50.7212 138.46 50.7212C139.502 50.7212 139.725 50.0509 139.725 49.1571V45.2842H142.332V49.6785C142.332 52.0619 140.62 52.8067 138.46 52.8067C136.225 52.8067 134.586 52.0619 134.586 49.6785V45.2842H137.193Z"
      fill="#004A93"
    />
    <path
      d="M146.95 46.2524C147.546 45.3586 148.291 45.0607 149.333 45.0607C151.27 45.0607 152.09 46.3269 152.09 48.1144V52.6578H149.482V49.0827C149.482 48.4123 149.632 47.1462 148.291 47.1462C147.249 47.1462 146.95 47.9654 146.95 48.8592V52.6578H144.343V45.3586H146.95V46.2524Z"
      fill="#004A93"
    />
    <path
      d="M158.048 47.2208C159.091 47.2208 159.761 47.9656 159.761 48.8594C159.761 49.7531 159.165 50.4979 158.122 50.4979C157.08 50.4979 156.484 49.7531 156.484 48.8594C156.41 47.9656 157.005 47.2208 158.048 47.2208ZM162.219 45.2843H159.612V46.0291C159.091 45.2843 158.345 44.9863 157.452 44.9863C155.218 44.9863 153.729 46.8485 153.729 48.9338C153.729 51.0194 155.218 52.6578 157.303 52.6578C158.271 52.6578 158.942 52.3599 159.612 51.6896V52.062C159.612 53.2537 159.314 54.1475 157.973 54.1475C157.75 54.1475 157.452 54.0731 157.229 53.9985C157.005 53.8496 156.782 53.7006 156.708 53.4772H153.802C154.025 55.4136 156.261 56.233 157.973 56.233C160.654 56.233 162.219 54.9667 162.219 51.913V45.2843Z"
      fill="#004A93"
    />
    <path
      d="M166.464 47.891C166.613 47.1462 167.283 46.7738 168.029 46.7738C168.699 46.7738 169.369 47.2207 169.518 47.891H166.464ZM171.901 49.0827C171.901 46.6249 170.412 45.0607 167.88 45.0607C165.495 45.0607 163.858 46.5503 163.858 48.9338C163.858 51.466 165.644 52.8067 168.103 52.8067C169.741 52.8067 171.38 51.9874 171.827 50.2743H169.369C169.071 50.7958 168.699 50.9447 168.103 50.9447C167.06 50.9447 166.464 50.3489 166.464 49.3061H171.901V49.0827Z"
      fill="#004A93"
    />
    <path
      d="M176.221 46.2524C176.817 45.3586 177.562 45.0607 178.604 45.0607C180.541 45.0607 181.36 46.3269 181.36 48.1144V52.6578H178.753V49.0827C178.753 48.4123 178.902 47.1462 177.562 47.1462C176.519 47.1462 176.221 47.9654 176.221 48.8592V52.6578H173.614V45.3586H176.221V46.2524Z"
      fill="#004A93"
    />
    <path
      d="M69.9374 21.7497H70.7568C73.4381 21.7497 77.5345 21.3773 77.5345 25.1758C77.5345 28.6764 73.4381 28.5275 70.9802 28.5275H69.9374V21.7497ZM74.3319 34.8583C80.1414 34.8583 85.8018 32.6984 85.8018 26.0696C85.8018 21.6752 83.8654 18.6215 79.322 18.0256V17.9512C81.9288 16.6851 82.8226 14.3016 82.8226 11.4714C82.8226 5.43837 79.0241 3.27844 73.4381 3.27844H61.6701V34.9327H74.3319V34.8583ZM69.9374 9.46044H70.5333C72.7678 9.46044 75.3001 9.6094 75.3001 12.5886C75.3001 15.2698 72.9912 15.7167 70.8312 15.7167H69.9374V9.46044Z"
      fill="#004A93"
    />
    <path
      d="M100.549 19.216C103.677 19.216 105.465 21.4505 105.465 24.0573C105.465 26.7386 103.677 28.8985 100.549 28.8985C97.4207 28.8985 95.6331 26.813 95.6331 24.0573C95.6331 21.4505 97.4207 19.216 100.549 19.216ZM112.838 13.3321H105.167V15.4175C103.603 13.5555 100.996 12.5873 98.5379 12.5873C92.207 12.5873 87.7382 17.8754 87.7382 24.0573C87.7382 30.2392 92.207 35.5273 98.6123 35.5273C101.145 35.5273 103.752 34.5591 105.092 32.3991H105.167V34.7825H112.838V13.3321Z"
      fill="#004A93"
    />
    <path
      d="M130.937 18.8437C129.745 18.2478 128.107 17.8009 126.841 17.8009C125.947 17.8009 124.383 18.1733 124.383 19.2906C124.383 20.7801 126.841 21.0036 127.883 21.1526C131.458 21.8974 134.14 23.5359 134.14 27.5578C134.14 33.2184 129.001 35.6018 123.936 35.6018C120.882 35.6018 117.828 34.708 115.296 33.144L118.052 27.7814C119.616 28.8985 122.223 29.9413 124.085 29.9413C125.053 29.9413 126.543 29.4944 126.543 28.3027C126.543 26.5896 124.085 26.5152 121.701 25.9193C119.318 25.3235 116.935 24.1318 116.935 20.2588C116.935 14.9706 121.701 12.5873 126.468 12.5873C128.926 12.5873 131.309 12.9596 133.544 14.0024L130.937 18.8437Z"
      fill="#004A93"
    />
    <path d="M144.642 34.857H136.97V0H144.642V34.857Z" fill="#004A93" />
    <path
      d="M155.069 20.929C155.515 18.6945 157.378 17.5773 159.612 17.5773C161.698 17.5773 163.56 18.918 163.932 20.929H155.069ZM171.082 24.5784C171.082 17.2794 166.762 12.6616 159.314 12.6616C152.313 12.6616 147.396 16.9815 147.396 24.1317C147.396 31.5797 152.76 35.6016 159.835 35.6016C164.751 35.6016 169.592 33.2927 170.784 28.228H163.411C162.591 29.6431 161.4 30.1646 159.761 30.1646C156.633 30.1646 154.994 28.4516 154.994 25.3978H171.082V24.5784Z"
      fill="#004A93"
    />
    <path
      d="M181.434 16.8326H181.509C182.999 14.1512 185.531 13.0341 188.51 13.0341H190V20.4076C188.958 19.8118 187.914 19.6628 186.798 19.6628C182.552 19.6628 181.509 22.4932 181.509 26.1426V34.8569H173.838V13.332H181.509V16.8326H181.434Z"
      fill="#004A93"
    />
    <path
      d="M33.2183 30.0165L40.443 37.3156L25.1745 52.5841L0 27.4097L25.1745 2.23523L47.7421 24.8773H58.3928V34.8577H42.9008L25.1745 17.1314L14.6726 27.6331L25.1745 38.0604L33.2183 30.0165Z"
      fill="#004A93"
    />
  </svg>
);

BaloiseLogoDe.displayName = 'BaloiseLogoDe';

export default BaloiseLogoDe;
