import * as React from 'react';
import { fs24 } from '@scout24ch/fs24-design-system';

export interface TemplateLayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  children?: React.ReactNode;
}

const contentStyle = {
  position: 'relative' as const,
  flex: '1 0 auto',
  pt: [8, null, 10],
  pb: [22, null, 26],
};

const Main: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => <fs24.main {...props} {...contentStyle} />;

export const TemplateLayout: React.FC<TemplateLayoutProps> = ({ header, footer, children }) => {
  const layoutStyle = {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'stretch',
    minHeight: '100vh',
    overflowX: 'hidden' as const,
  };

  return (
    <fs24.div {...layoutStyle}>
      {header}
      {children && <Main>{children}</Main>}
      {footer}
    </fs24.div>
  );
};
