/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ElviaLogo = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--elvia-logo ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 2000 385"
    {...props}
  >
    <g fill="#DB001C">
      <path d="M164.2 57.2C110.8 117.3 45 192.5 45.2 193.1c.2.4 29.5 33.9 65.2 74.3l64.9 73.6H275V241h-95v-97h95V45h-99.9l-10.9 12.2zM373 94.5V144h95v97h-95v100l50.2-.2 50.2-.3 64.8-73.5c35.6-40.4 64.6-74 64.3-74.7-.2-.7-29.3-34.1-64.7-74.2l-64.3-73-50.2-.1H373v49.5z" />
    </g>
    <path d="M647 193.5V339h258v-63H733v-54h152v-61H733v-49h165V48H647v145.5zM936 193.5V339h226v-63h-137V48h-89v145.5zM1069.2 48.9c.1.5 31.8 66 70.3 145.5l70 144.6 55.5-.3 55.4-.2 71.2-144c39.2-79.2 71.3-144.6 71.3-145.3.1-1-9.8-1.2-48.1-1l-48.2.3-48.7 109.5c-26.8 60.2-49.1 110.1-49.6 110.8-.5.8-18.9-38.7-51-109.5l-50.2-110.8-49.1-.3c-29.9-.1-49 .1-48.8.7zM1469 193.5V339h88V48h-88v145.5zM1630.7 191.7c-38.3 79.1-69.9 144.6-70.3 145.5-.6 1.7 2.1 1.8 48.4 1.8h48.9l7.1-16.3c3.8-8.9 10-23.1 13.7-31.5l6.7-15.2 70.5.2 70.4.3 14.1 31.3 14.1 31.2h49.9c30 0 49.8-.4 49.6-.9-.1-.5-32.4-66-71.7-145.5L1810.7 48H1700.4l-69.7 143.7zm145.8-27l22.2 49.8-8.6.8c-4.7.4-24.6.6-44.2.5l-35.5-.3 21.5-50.7c11.8-27.9 21.7-50.5 21.9-50.3.3.3 10.5 22.9 22.7 50.2z" />
  </svg>
);

ElviaLogo.displayName = 'ElviaLogo';

export default ElviaLogo;
