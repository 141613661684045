import { SortingOptions } from '../../enums';
import { ActionType } from './resultSortingOrder.actionCreators';
import * as actions from './resultSortingOrder.actionTypes';

export type ResultSortingOrderState = Readonly<SortingOptions>;

export const initialState: ResultSortingOrderState = SortingOptions.MatchingScore;

const sortingOrderReducer = (
  state: ResultSortingOrderState = initialState,
  action: ActionType,
): ResultSortingOrderState => {
  switch (action.type) {
    case actions.SET_RESULT_SORTING_ORDER:
      return action.order;
    default:
      return state;
  }
};

export default sortingOrderReducer;
