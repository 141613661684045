import { ValidationResult } from '@scout24ch/fs24-forms';
import { DateError, Field } from '../../../enums';
import { CityError, DamageError } from '../../../types';
import { ActionType } from './inquiryError.actionCreators';
import * as actions from './inquiryError.actionTypes';
import * as types from './types';

export interface InternalInquiryErrorState {
  [Field.PossessionType]: boolean;
  [Field.HouseholdType]: boolean;
  [Field.HouseholdOccupancyType]: boolean;
  [Field.IsGroundFloorApartment]: boolean;
  [Field.IsMassive]: boolean;
  [Field.NumberOfRooms]: boolean;
  [Field.City]: CityError;
  [Field.Furnishing]: boolean;
  [Field.Clothing]: boolean;
  [Field.ElectricalAppliances]: boolean;
  [Field.GrossNegligence]: boolean;
  [Field.NonOwnerDriverInsurance]: boolean;
  [Field.GrossNegligence]: boolean;
  [Field.NonOwnerDriverInsurance]: boolean;
  [Field.DateOfBirth]: DateError | null;
  [Field.NumberOfInsurees]: boolean;
  [Field.NumberOfResidentsAgedUnder16]: boolean;
  [Field.IsDogOwner]: boolean;
  [Field.NumberOfDogs]: boolean;
  [Field.ChildrenDatesOfBirth]: Array<DateError | null>;
  [Field.EmailAddress]: string | null;
  [Field.InsuranceStartDate]: types.InsuranceStartDateError;
  [Field.InsuranceSum]: ValidationResult | null;
  [Field.SelfInflictedDamagesAmount]: boolean;
  [Field.TheftNotAtHomeAmount]: boolean;
  [Field.HasReceivedCompensationForHousehold]: boolean;
  [Field.HasReceivedCompensationForPrivateLiability]: boolean;
  [Field.ContractTerminatedByPreviousInsurer]: boolean;
  [Field.NumberOfHouseholdDamageCompensations]: boolean;
  [Field.NumberOfPrivateLiabilityDamageCompensations]: boolean;
  [Field.HouseholdDamages]: DamageError[];
  [Field.PrivateLiabilityDamages]: DamageError[];
  [Field.PreviousInsurer]: boolean;
  [Field.ReasonForCancellation]: boolean;
  [Field.DataProtectionAccepted]: boolean;
}

export type InquiryErrorState = Readonly<InternalInquiryErrorState>;

export const initialState: InquiryErrorState = {
  [Field.PossessionType]: false,
  [Field.HouseholdType]: false,
  [Field.HouseholdOccupancyType]: false,
  [Field.IsGroundFloorApartment]: false,
  [Field.IsMassive]: false,
  [Field.NumberOfRooms]: false,
  [Field.City]: { postalCode: null, name: false, canton: false },
  [Field.Furnishing]: false,
  [Field.Clothing]: false,
  [Field.ElectricalAppliances]: false,
  [Field.GrossNegligence]: false,
  [Field.NonOwnerDriverInsurance]: false,
  [Field.DateOfBirth]: null,
  [Field.NumberOfInsurees]: false,
  [Field.NumberOfResidentsAgedUnder16]: false,
  [Field.IsDogOwner]: false,
  [Field.NumberOfDogs]: false,
  [Field.ChildrenDatesOfBirth]: [],
  [Field.EmailAddress]: null,
  [Field.InsuranceSum]: null,
  [Field.InsuranceStartDate]: null,
  [Field.SelfInflictedDamagesAmount]: false,
  [Field.TheftNotAtHomeAmount]: false,
  [Field.HasReceivedCompensationForHousehold]: false,
  [Field.HasReceivedCompensationForPrivateLiability]: false,
  [Field.ContractTerminatedByPreviousInsurer]: false,
  [Field.NumberOfHouseholdDamageCompensations]: false,
  [Field.NumberOfPrivateLiabilityDamageCompensations]: false,
  [Field.ReasonForCancellation]: false,
  [Field.PreviousInsurer]: false,
  [Field.HouseholdDamages]: [],
  [Field.PrivateLiabilityDamages]: [],
  [Field.DataProtectionAccepted]: false,
};

const inquiryErrorReducer = (state: InquiryErrorState = initialState, action: ActionType): InquiryErrorState => {
  switch (action.type) {
    case actions.SET_DAMAGE_AMOUNT_ERROR:
      const newArr = [...state[action.name]];
      newArr[action.index] = { ...newArr[action.index], amount: action.error };
      return {
        ...state,
        [action.name]: newArr,
      };
    case actions.SET_MOBILIAR_INQUIRY_ERROR:
      return { ...action.errors };
    case actions.UPDATE_DATE_ERROR_ARRAY:
      return { ...state, [Field.ChildrenDatesOfBirth]: state.childrenDatesOfBirth.slice(0, action.newSize) };
    case actions.SET_DATE_ERROR:
      const newArray: Array<DateError | null> = [...state.childrenDatesOfBirth];
      newArray[action.index] = action.error;
      return { ...state, [Field.ChildrenDatesOfBirth]: newArray };
    case actions.SET_MOBILIAR_FIELD_ERROR:
      // Resolves 'Maximum update depth exceeded' error on select dropdown field. - temporary fix
      if (
        Array.isArray(state[action.field]) &&
        Array.isArray(action.error) &&
        !state[action.field].length &&
        !action.error.length
      ) {
        return state;
      }

      if (state[action.field] !== action.error) {
        return { ...state, [action.field]: action.error };
      }

      return state;
    default:
      return state;
  }
};

export default inquiryErrorReducer;
