import { FeatureFlagState } from './featureFlags.const';

export const STORE_FEATURE_FLAGS = '@@FEATURE_FLAGS/STORE';

export const setFeatureFlags = (values: FeatureFlagState) =>
  ({
    type: STORE_FEATURE_FLAGS,
    values,
  } as const);

export type ActionType = ReturnType<typeof setFeatureFlags>;
