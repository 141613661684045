import Router from 'next/router';
import { Page, ProductType } from '../../enums';

export const goToHouseholdInquiryRoute = () => {
  Router.push('/inquiry');
};

export const goToHouseholdResultRoute = (requestId: string) => {
  const previousPath = Router.pathname;
  const resultPath = '/result/[requestId]';

  if (Router.query.requestId === requestId && previousPath === resultPath) {
    Router.reload();
    return;
  }

  Router.push({ pathname: resultPath, query: { requestId } });
};

export const goToOfferRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/offer/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToDirectDealRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/direct-deal/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToOfferCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/offer-completion/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToDirectDealCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/direct-deal-completion/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToDetailsRoute = (requestId: string, premiumIds: string[]) => {
  Router.push({ pathname: '/details/[requestId]/[premiumIds]', query: { requestId, premiumIds: premiumIds.join() } });
};

export const goToErrorRoute = (productType: ProductType, requestId: string, comingFrom: Page) => {
  Router.push({ pathname: '/error', query: { productType, requestId, comingFrom } });
};

export const goToPrivateLiabilityInquiryRoute = () => {
  Router.push(`/private-liability/inquiry`);
};

export const goToPrivateLiabilityResultRoute = (requestId: string) => {
  const previousPath = Router.pathname;
  const resultPath = '/private-liability/results/[requestId]';

  if (Router.query.requestId === requestId && previousPath === resultPath) {
    Router.reload();
    return;
  }

  Router.push({ pathname: resultPath, query: { requestId } });
};

export const goToPrivateLiabilityDetailsRoute = (requestId: string, premiumIds: string[]) => {
  Router.push({
    pathname: '/private-liability/details/[requestId]/[premiumIds]',
    query: { requestId, premiumIds: premiumIds.join() },
  });
};

export const goToPrivateLiabilityOfferRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/private-liability/offer/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToPrivateLiabilityOfferCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({
    pathname: '/private-liability/offer-completion/[requestId]/[premiumId]',
    query: { requestId, premiumId },
  });
};

export const goToPrivateLiabilityDirectDealRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/private-liability/direct-deal/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToPrivateLiabilityDirectDealCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({
    pathname: '/private-liability/direct-deal-completion/[requestId]/[premiumId]',
    query: { requestId, premiumId },
  });
};

export const goToMobiliarInquiryRoute = () => {
  Router.push('/mobiliar-inquiry');
};

export const goToMobiliarResultRoute = (requestId: string) => {
  const previousPath = Router.pathname;
  const resultPath = '/mobiliar-inquiry/result/[requestId]';

  if (Router.query.requestId === requestId && previousPath === resultPath) {
    Router.reload();
    return;
  }

  Router.push({ pathname: resultPath, query: { requestId } });
};

export const goToMobiliarOfferRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/mobiliar-inquiry/offer/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToMobiliarDirectDealRoute = (requestId: string, premiumId: string) => {
  Router.push({ pathname: '/mobiliar-inquiry/direct-deal/[requestId]/[premiumId]', query: { requestId, premiumId } });
};

export const goToMobiliarOfferCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({
    pathname: '/mobiliar-inquiry/offer-completion/[requestId]/[premiumId]',
    query: { requestId, premiumId },
  });
};

export const goToMobiliarDirectDealCompletionRoute = (requestId: string, premiumId: string) => {
  Router.push({
    pathname: '/mobiliar-inquiry/direct-deal-completion/[requestId]/[premiumId]',
    query: { requestId, premiumId },
  });
};

export const goToMobiliarDetailsRoute = (requestId: string, premiumIds: string[]) => {
  Router.push({
    pathname: '/mobiliar-inquiry/details/[requestId]/[premiumIds]',
    query: { requestId, premiumIds: premiumIds.join() },
  });
};

export const goToResultRoute = (requestId: string, productType: ProductType) => {
  switch (productType) {
    case ProductType.PrivateLiability:
      goToPrivateLiabilityResultRoute(requestId);
    case ProductType.Household:
      goToHouseholdResultRoute(requestId);
    case ProductType.Mobiliar:
      goToMobiliarResultRoute(requestId);
    default:
      return null;
  }
};

export const goToInquiryRoute = (productType: ProductType) => {
  switch (productType) {
    case ProductType.PrivateLiability:
      goToPrivateLiabilityInquiryRoute();
    case ProductType.Household:
      goToHouseholdInquiryRoute();
    case ProductType.Mobiliar:
      goToMobiliarInquiryRoute();
    default:
      return null;
  }
};
