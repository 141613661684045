import { __TEST__ } from '@scout24ch/fs24-utils';
import { captureException } from 'utils/sentry';

export function fetchConfig(): Promise<Record<string, string>> {
  if (__TEST__) {
    return Promise.resolve(Object.create(process.env));
  }

  return import('@scout24ch/fs24-config').then(({ fetchConfig }) => fetchConfig('', { onError: captureException }));
}
