// Env variables from the process is available for server all time
// and for client only during development time.
import getNextConfig from 'next/config';
import runtimeEnvironment from './publicRuntimeConfig';

// Env variables from next config is available just after the initialisation of the app.
const nextEnvironment = getNextConfig();

export interface Env {
  NODE_ENV: string;

  LOG_LEVEL: string;
  ENVIRONMENT: string;

  PORT: string;
  FS_MAIN_DOMAIN: string;
  HOUSEHOLD_API_HOST: string;
  HOUSEHOLD_APP_HOST: string;
  HOUSEHOLD_API_VERSION: string;
  CMS_URL: string;

  GTM_IS24_ID: string;
  GTM_IS24_AUTH: string;
  GTM_IS24_ENV: string;

  GTM_HG_ID: string;
  GTM_HG_AUTH: string;
  GTM_HG_ENV: string;

  GTM_ID: string;
  GTM_AUTH: string;
  GTM_ENV: string;

  TRANSLATION_RELOAD_INTERVAL: string;
  TRANSLATION_CIMODE_SUPPORTED: string;

  GUEST_PASSWORD: string;
  ENABLE_BASIC_AUTH: string;

  VERSION: string;
  GITCOMMIT: string;

  SENTRY_ENABLED: 'true' | 'false';
  SENTRY_DSN: string;
  SENTRY_TRACES_SAMPLE_RATE: string;
  SENTRY_LOG_TO_CONSOLE: 'true' | 'false';
}

/**
 * Abstract the logic from where env variables come from.
 */
export const environment: Env = nextEnvironment?.publicRuntimeConfig ?? runtimeEnvironment;

export const isProd: boolean = environment.ENVIRONMENT === 'PROD';
export const isTest: boolean = environment.ENVIRONMENT === 'TEST';
