import { FeatureFlagState } from './featureFlags.const';
import { ActionType, STORE_FEATURE_FLAGS } from './featureFlags.actionCreators';

export const initialState: FeatureFlagState = {} as FeatureFlagState;

const featureFlagsReducer = (state: FeatureFlagState = initialState, action: ActionType) => {
  switch (action.type) {
    case STORE_FEATURE_FLAGS:
      return { ...state, ...action.values };
    default:
      return state;
  }
};

export default featureFlagsReducer;
