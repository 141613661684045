export enum ClaimType {
  Theft = 'theft',
  Fire = 'fire',
  ActsOfGod = 'actsOfGod',
  Burglary = 'burglary',
  Robbery = 'robbery',
  Flooding = 'flooding',
  Glass = 'glass',
  RentersDamage = 'rentersDamage',
  BodilyInjury = 'bodilyInjury',
  PropertyDamage = 'propertyDamage',
}
