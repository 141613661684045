import { Field } from '../../enums';
import { asInt } from '../../utils';
import { RootState } from '../rootReducer';

type State = Pick<RootState, 'inquiry'>;

export const getHouseholdInquiryFields = (rootState: State) => rootState.inquiry;

//TODO_remove-in-future
export const getGoBackToFunnel = (state: State) => state.inquiry.goBackToFunnel;

export const getDamagesSectionIsDone = (state: State) =>
  state.inquiry.contractTerminatedByPreviousInsurer === false || state.inquiry.reasonForCancellation !== null;

export const getHasSingleResident = (state: State) => {
  if (state.inquiry.numberOfInsurees !== null) {
    return parseInt(state.inquiry.numberOfInsurees, 10) === 1;
  }
  return false;
};

export const getNumberOfChildren = (state: State) => {
  const childrenBirthDates = state.inquiry.childrenDatesOfBirth;
  if (childrenBirthDates) {
    return childrenBirthDates.length;
  }
  return 0;
};

export const getNumberOfAdultResidents = (state: State) => {
  return asInt(state.inquiry.numberOfInsurees) - getNumberOfChildren(state) - 1;
};

export const getInsuranceSumData = (state: State) => {
  return {
    rooms: getHouseholdInquiryFields(state).numberOfRooms,
    furnishing: getHouseholdInquiryFields(state).furnishing,
    clothing: getHouseholdInquiryFields(state).clothing,
    electricalAppliances: getHouseholdInquiryFields(state).electricalAppliances,
    insurees: getHasSingleResident(state) ? '1' : getHouseholdInquiryFields(state).numberOfInsurees,
    minors: getHasSingleResident(state) ? '0' : getHouseholdInquiryFields(state).numberOfResidentsAgedUnder16,
  };
};

export const getQuickAdjustFields = (state: State) => {
  return {
    [Field.TheftNotAtHome]: state.inquiry.theftNotAtHome,
    [Field.LuggageInsurance]: state.inquiry.luggageInsurance,
    [Field.CyberInsurance]: state.inquiry.cyberInsurance,
    [Field.GlassFurniture]: state.inquiry.glassFurniture,
    [Field.SelfInflictedDamages]: state.inquiry.selfInflictedDamages,
  };
};
