import { HeaderWidget } from '@scout24ch/fs24-widgets';
import { HeaderLanguageItem } from '@scout24ch/fs24-ui';
import { useCallback } from 'react';
import { useSelector, useDispatch } from '../../state/rootReducer';
import { updateLanguage, fetchLanguage } from '../../state/language/language.actionCreators';
import { getCurrentLanguageCode } from '../../state/language/language.selectors';
import { Language } from '../../enums';
import { CMS_URL } from '../../config';
import { useUser } from '../../hooks';

export function HeaderWidgetRenderer(): JSX.Element {
  const currentLanguageCode = useSelector(getCurrentLanguageCode);
  const dispatch = useDispatch();
  const { user, loginWithRedirect, logout, getAccessTokenSilently } = useUser();

  const login = useCallback(() => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  }, [loginWithRedirect]);

  const signup = useCallback(() => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      authorizationParams: { screen_hint: 'signup' },
    });
  }, [loginWithRedirect]);

  const logoutTo = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <HeaderWidget
      logoHref={`${CMS_URL}/${currentLanguageCode}`}
      onLanguageClick={(languageItem: HeaderLanguageItem) => {
        dispatch(updateLanguage(languageItem.shortCode as Language));
        dispatch(fetchLanguage());
      }}
      user={user}
      getAccessToken={getAccessTokenSilently}
      onLogin={login}
      onSignup={signup}
      onLogout={logoutTo}
    />
  );
}

HeaderWidgetRenderer.displayName = 'HeaderWidgetRenderer';
