export enum Field {
  PossessionType = 'possessionType',
  HouseholdType = 'householdType',
  IsGroundFloorApartment = 'isGroundFloorApartment',
  HouseholdOccupancyType = 'householdOccupancyType',
  IsMassive = 'isMassive',
  NumberOfRooms = 'numberOfRooms',
  City = 'city',
  PostalCode = 'postalCode',
  NonOwnerDriverInsurance = 'nonOwnerDriverInsurance',
  GrossNegligence = 'grossNegligence',
  DateOfBirth = 'dateOfBirth',
  NumberOfInsurees = 'numberOfInsurees',
  NumberOfChildren = 'numberOfChildren',
  IsDogOwner = 'isDogOwner',
  NumberOfDogs = 'numberOfDogs',
  InsuranceStartDate = 'insuranceStartDate',
  ChildrenDatesOfBirth = 'childrenDatesOfBirth',
  CompensationForPrivateLiability = 'compensationForPrivateLiability',
  ContractTerminatedByPreviousInsurer = 'contractTerminatedByPreviousInsurer',
  NumberOfPrivateLiabilityCompensations = 'numberOfPrivateLiabilityCompensations',
  PrivateLiabilityDamages = 'privateLiabilityDamages',
  PreviousInsurer = 'previousInsurer',
  ReasonForCancellation = 'reasonForCancellation',
  EmailAddress = 'emailAddress',
  Furnishing = 'furnishing',
  Clothing = 'clothing',
  ElectricalAppliances = 'electricalAppliances',
  NumberOfResidentsAgedUnder16 = 'numberOfResidentsAgedUnder16',
  PrivateLiability = 'privateLiability',
  TheftNotAtHome = 'theftNotAtHome',
  LuggageInsurance = 'luggageInsurance',
  CyberInsurance = 'cyberInsurance',
  GlassFurniture = 'glassFurniture',
  SelfInflictedDamages = 'selfInflictedDamages',
  SelfInflictedDamagesAmount = 'selfInflictedDamagesAmount',
  TheftNotAtHomeAmount = 'theftNotAtHomeAmount',
  HasReceivedCompensationForHousehold = 'hasReceivedCompensationForHousehold',
  HasReceivedCompensationForPrivateLiability = 'hasReceivedCompensationForPrivateLiability',
  NumberOfHouseholdDamageCompensations = 'numberOfHouseholdDamageCompensations',
  NumberOfPrivateLiabilityDamageCompensations = 'numberOfPrivateLiabilityDamageCompensations',
  HouseholdDamages = 'householdDamages',
  InsuranceSum = 'insuranceSum',
  RecommendedInsuranceSum = 'recommendedInsuranceSum',
  DataProtectionAccepted = 'dataProtectionAccepted',
}
