import * as React from 'react';
import { Language } from '../enums';

/**
 * Provides the current language as context. Initial value is 'de',
 * and the provider is rendered inside the root app to make this context
 * accessible to all other components.
 */
export const LanguageContext = React.createContext<Language>(Language.De);

LanguageContext.displayName = 'LanguageContext';

export interface LanguageProviderConfig {
  children: JSX.Element;
  lang: string;
}

/**
 * Convenience method: Provider for LanguageContext
 * @param {{ lang: string, children: any }} config
 */
export const LanguageProvider = LanguageContext.Provider;

/**
 * Convenience method: Consumer of LanguageContext
 * @param {{ render: (lang: string) => JSX.Element }} config
 */
export const LanguageConsumer = LanguageContext.Consumer;
