import { asInt } from '../../utils';
import { RootState } from '../rootReducer';

type State = Pick<RootState, 'privateLiabilityPremiums'>;

export const getPrivateLiabilityPremiums = (state: State) => {
  return state.privateLiabilityPremiums;
};

export const getPrivateLiabilityPremiumsByIds = (state: State, ids: string[]) => {
  return getPrivateLiabilityPremiums(state).filter((premium) => ids.includes(String(premium.productId)));
};

export const getPrivateLiabilityPremiumById = (state: State, id: string) => {
  return getPrivateLiabilityPremiums(state).find((premium) => premium.productId === asInt(id));
};
