import { IMAGE_TYPES, APPLICATION_TYPES, APPLICATION_EXTENSIONS, IMAGE_EXTENSIONS } from '@scout24ch/fs24-ui';

// in ms
export const INLINE_VALIDATION_INTERVAL = 500;

// File upload
export const ALLOWED_FILE_EXTENSIONS = [...IMAGE_EXTENSIONS, ...APPLICATION_EXTENSIONS];
export const ALLOWED_FILE_TYPES = [...IMAGE_TYPES, ...APPLICATION_TYPES];
// 15 MB
export const FILE_SIZE_LIMIT = 15 * 1024 * 1024;
