export enum InsuranceCompany {
  Visana = 'visana',
  Mobiliar = 'mobiliar',
  Allianz = 'allianz',
  Axa = 'axa',
  Basler = 'basler',
  Elvia = 'elvia',
  Generali = 'generali',
  Helvetia = 'helvetia',
  Smile = 'smile',
  Vaudoise = 'vaudoise',
  Zurich = 'zurich',
  Other = 'other',
  NoPreviousInsuranceCompany = 'noPreviousInsuranceCompany',
  Simpego = 'simpego',
}
