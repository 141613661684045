import { call, put, takeEvery, select } from 'redux-saga/effects';
import { requestInsuranceSum } from '../../api/insuranceSum';
import { FETCH_INSURANCE_SUM } from '../../state/inquiry/inquiry.actionTypes';
import { FETCH_MOBILIAR_INSURANCE_SUM } from '../../state/mobiliar/inquiry/inquiry.actionTypes';
import { setHouseholdInquiryField } from '../../state/inquiry/inquiry.actionCreators';
import { getInsuranceSumData } from '../../state/inquiry/inquiry.selectors';
import { getInsuranceSumData as getMobiliarInsuranceSumData } from '../../state/mobiliar/inquiry/inquiry.selectors';
import { captureException } from '../../utils/sentry';
import { Field } from '../../enums';
import { setMobiliarInquiryField } from '../../state/mobiliar/inquiry/inquiry.actionCreators';

export function* workFetchInquirySum() {
  try {
    const insuranceSumData = yield select(getInsuranceSumData);
    if (Object.values(insuranceSumData).every((value) => value !== null)) {
      const insuranceSum = yield call(requestInsuranceSum, insuranceSumData);
      yield put(setHouseholdInquiryField(Field.InsuranceSum, insuranceSum));
      yield put(setHouseholdInquiryField(Field.RecommendedInsuranceSum, insuranceSum));
    }
  } catch (err) {
    captureException(err);
  }
}

export function* workFetchMobiliarInquirySum() {
  try {
    const insuranceSumData = yield select(getMobiliarInsuranceSumData);
    if (Object.values(insuranceSumData).every((value) => value !== null)) {
      const insuranceSum = yield call(requestInsuranceSum, insuranceSumData);
      yield put(setMobiliarInquiryField(Field.InsuranceSum, insuranceSum));
      yield put(setMobiliarInquiryField(Field.RecommendedInsuranceSum, insuranceSum));
    }
  } catch (err) {
    captureException(err);
  }
}

export function* watchInsuranceSum() {
  try {
    yield takeEvery(FETCH_INSURANCE_SUM, workFetchInquirySum);
  } catch (err) {
    captureException(err);
  }
}

export function* watchMobiliarInsuranceSum() {
  try {
    yield takeEvery(FETCH_MOBILIAR_INSURANCE_SUM, workFetchMobiliarInquirySum);
  } catch (err) {
    captureException(err);
  }
}
