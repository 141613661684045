import * as React from 'react';
import Image from 'next/image';
import ErrorIllustration from '@scout24ch/fs24-assets/img/illustrations/error.svg';
import { Button } from '@scout24ch/fs24-design-system';
import { useRouter } from 'next/router';
import { Page } from '../../enums';
import { useProductType, useTranslations } from '../../hooks';
import { doSetLoading } from '../../state/loading/loading.actionCreators';
import { useDispatch } from '../../state/rootReducer';
import { goToResultRoute, goToInquiryRoute } from '../../utils/router/clientNavigationUtils';

const ErrorPage: React.FC = () => {
  const { comingFrom, requestId } = useRouter().query;
  const productType = useProductType();
  const t = useTranslations();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(doSetLoading(false));
    // should only happen on mount
    //eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if (comingFrom === Page.Offer) {
      goToResultRoute(String(requestId), productType);
      return;
    }

    goToInquiryRoute(productType);
  };

  return (
    <div className="error-page">
      <div className="error-page-wrapper">
        <div className="icon-text-wrapper">
          <Image src={ErrorIllustration} alt="error-illustration" height={230} />
          <div className="error-text">
            <h2 className="title">{t('errorPageTitle')}</h2>
            <p className="info">{comingFrom === Page.Offer ? t('offertext') : t('inquirytext')}</p>
            <Button width="full" variant="primary" onClick={handleClick}>
              {comingFrom === Page.Offer ? t('offerBackButtonLabel') : t('inquiryBackButtonLabel')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
