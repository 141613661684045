import React from 'react';
import { Language } from '../../enums';
import { getCurrentLanguageCode } from '../../state/language/language.selectors';
import { useSelector } from '../../state/rootReducer';
import {
  AllianzLogo,
  AxaLogo,
  BaloiseLogoDe,
  BaloiseLogoFr,
  BaloiseLogoIt,
  ElviaLogo,
  GeneraliLogo,
  HelvetiaLogo,
  MobiLogoDe,
  MobiLogoFr,
  MobiLogoIt,
  SmileLogo,
  TcsLogo,
  VisanaDe,
  VisanaFr,
  VisanaIt,
  ZurichLogo,
  SimpegoLogo,
} from '../Icon/insurances';

export interface LogoRendererProps {
  insurance: string;
  className?: string;
  svgWidth: string;
  svgHeight: string;
}

const getLogoComponent = (name: string, language: Language, svgWidth: string, svgHeight: string) => {
  switch (name) {
    case 'allianz':
      return <AllianzLogo viewBox="0 -5 95 50" width={svgWidth} height={svgHeight} />;
    case 'axa':
      return <AxaLogo width={svgWidth} height={svgHeight} />;
    case 'baloise':
      const baloiseViewBox = '-25 -20 250 100';
      switch (language) {
        case 'de':
          return <BaloiseLogoDe viewBox={baloiseViewBox} width={svgWidth} height={svgHeight} />;
        case 'fr':
          return <BaloiseLogoFr viewBox={baloiseViewBox} width={svgWidth} height={svgHeight} />;
        case 'it':
          return <BaloiseLogoIt viewBox={baloiseViewBox} width={svgWidth} height={svgHeight} />;
        default:
          return null;
      }
    case 'visana':
      switch (language) {
        case 'de':
          return <VisanaDe width={svgWidth} height={svgHeight} />;
        case 'fr':
          return <VisanaFr width={svgWidth} height={svgHeight} />;
        case 'it':
          return <VisanaIt width={svgWidth} height={svgHeight} />;
        default:
          return null;
      }
    case 'simpego':
      return <SimpegoLogo width={svgWidth} height={svgHeight} />;
    case 'elvia':
      return <ElviaLogo viewBox="-30 0 2000 385" width={svgWidth} height={svgHeight} />;
    case 'generali':
      return <GeneraliLogo viewBox="-100 0 1100.3 709.6" width={svgWidth} height={svgHeight} />;
    case 'helvetia':
      return <HelvetiaLogo viewBox="-35 -25 250 100" width={svgWidth} height={svgHeight} />;
    case 'mobiliar':
      const mobiliarViewBox = '-8 0 160 23';
      switch (language) {
        case 'de':
        case 'en':
          return <MobiLogoDe viewBox={mobiliarViewBox} width={svgWidth} height={svgHeight} />;
        case 'fr':
          return <MobiLogoFr viewBox={mobiliarViewBox} width={svgWidth} height={svgHeight} />;
        case 'it':
          return <MobiLogoIt viewBox={mobiliarViewBox} width={svgWidth} height={svgHeight} />;
        default:
          return null;
      }
    case 'zurich':
      return <ZurichLogo width={svgWidth} height={svgHeight} viewBox="-10 0 152 88" />;
    case 'smile':
      return <SmileLogo width={svgWidth} height={svgHeight} />;
    case 'tcs':
      return <TcsLogo width={svgWidth} height={svgHeight} viewBox="320 -25 70 800" />;
    default:
      return null;
  }
};

export const LogoRenderer: React.FC<LogoRendererProps> = (props) => {
  const currentLanguageCode = useSelector(getCurrentLanguageCode);
  return (
    <div className={`logo-renderer ${props.className || ''}`}>
      {getLogoComponent(props.insurance, currentLanguageCode, props.svgWidth, props.svgHeight)}
    </div>
  );
};

export default LogoRenderer;
