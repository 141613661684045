import { Field } from '../../../enums';
import { asInt } from '../../../utils';
import { RootState } from '../../rootReducer';

type State = Pick<RootState, 'mobiliarInquiry'>;

export const getMobiliarInquiryFields = (rootState: State) => rootState.mobiliarInquiry;

//TODO_remove-in-future
export const getGoBackToFunnel = (state: State) => state.mobiliarInquiry.goBackToFunnel;

export const getDamagesSectionIsDone = (state: State) =>
  state.mobiliarInquiry.contractTerminatedByPreviousInsurer === false ||
  state.mobiliarInquiry.reasonForCancellation !== null;

export const getHasSingleResident = (state: State) => {
  if (state.mobiliarInquiry.numberOfInsurees !== null) {
    return parseInt(state.mobiliarInquiry.numberOfInsurees, 10) === 1;
  }
  return false;
};

export const getNumberOfChildren = (state: State) => {
  const childrenBirthDates = state.mobiliarInquiry.childrenDatesOfBirth;
  if (childrenBirthDates) {
    return childrenBirthDates.length;
  }
  return 0;
};

export const getNumberOfAdultResidents = (state: State) => {
  return asInt(state.mobiliarInquiry.numberOfInsurees) - getNumberOfChildren(state) - 1;
};

export const getInsuranceSumData = (state: State) => {
  return {
    rooms: getMobiliarInquiryFields(state).numberOfRooms,
    furnishing: getMobiliarInquiryFields(state).furnishing,
    clothing: getMobiliarInquiryFields(state).clothing,
    electricalAppliances: getMobiliarInquiryFields(state).electricalAppliances,
    insurees: getHasSingleResident(state) ? '1' : getMobiliarInquiryFields(state).numberOfInsurees,
    minors: getHasSingleResident(state) ? '0' : getMobiliarInquiryFields(state).numberOfResidentsAgedUnder16,
  };
};

export const getQuickAdjustFields = (state: State) => {
  return {
    [Field.TheftNotAtHome]: state.mobiliarInquiry.theftNotAtHome,
    [Field.LuggageInsurance]: state.mobiliarInquiry.luggageInsurance,
    [Field.CyberInsurance]: state.mobiliarInquiry.cyberInsurance,
    [Field.GlassFurniture]: state.mobiliarInquiry.glassFurniture,
    [Field.SelfInflictedDamages]: state.mobiliarInquiry.selfInflictedDamages,
  };
};

export const getReferrerPage = (state: State) => state.mobiliarInquiry.referrerPage;
