// @ts-check

/**
 * @typedef { import('./environment').Env } Env
 */

/**
 * The following env variables are available on server and browser client.
 * Therefore, DO NOT export sensitive environment variables
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 * @type {Env}
 */
module.exports = {
  ENVIRONMENT: process.env.ENVIRONMENT || 'local',
  PORT: process.env.PORT || '3000',
  LOG_LEVEL: process.env.LOG_LEVEL || 'warn',
  FS_MAIN_DOMAIN: process.env.FS_MAIN_DOMAIN || 'localhost',
  HOUSEHOLD_API_HOST: process.env.HOUSEHOLD_API_HOST || 'http://localhost:9779',
  HOUSEHOLD_APP_HOST: process.env.HOUSEHOLD_APP_HOST || 'http://localhost:3000',
  HOUSEHOLD_API_VERSION: process.env.HOUSEHOLD_API_VERSION || '1',
  CMS_URL: process.env.CMS_URL || 'http://localhost:9779',
  GTM_IS24_ID: process.env.GTM_IS24_ID || 'GTM-xxxxxxx',
  GTM_IS24_AUTH: process.env.GTM_IS24_AUTH || 'xxxxxxxxxxxxxxxxxxxxxx',
  GTM_IS24_ENV: process.env.GTM_IS24_ENV || 'env-xx',
  GTM_HG_ID: process.env.GTM_HG_ID || 'GTM-xxxxxxx',
  GTM_HG_AUTH: process.env.GTM_HG_AUTH || 'xxxxxxxxxxxxxxxxxxxxxx',
  GTM_HG_ENV: process.env.GTM_HG_ENV || 'env-xx',
  GTM_ID: process.env.GTM_ID || 'GTM-xxxxxxx',
  GTM_AUTH: process.env.GTM_AUTH || 'xxxxxxxxxxxxxxxxxxxxxx',
  GTM_ENV: process.env.GTM_ENV || 'env-xx',
  TRANSLATION_RELOAD_INTERVAL: process.env.TRANSLATION_RELOAD_INTERVAL || '60000',
  TRANSLATION_CIMODE_SUPPORTED: process.env.TRANSLATION_CIMODE_SUPPORTED || 'true',
  ENABLE_BASIC_AUTH: process.env.ENABLE_BASIC_AUTH || false,
  GUEST_PASSWORD: process.env.GUEST_PASSWORD || 'xxx',
  VERSION: process.env.VERSION || '',
  GITCOMMIT: process.env.GITHUB_SHA || '',
  SENTRY_ENABLED: process.env.SENTRY_ENABLED || 'false',
  SENTRY_DSN: process.env.SENTRY_DSN || '',
  SENTRY_TRACES_SAMPLE_RATE: process.env.SENTRY_TRACES_SAMPLE_RATE || '0',
  SENTRY_LOG_TO_CONSOLE: process.env.SENTRY_LOG_TO_CONSOLE || 'true',
  NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_SCOPE: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
  NEXT_PUBLIC_AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,

  LOCATION_SERVICE_URL: process.env.LOCATION_SERVICE_URL,
  NEXT_PUBLIC_CF_CLIENT_ID: process.env.NEXT_PUBLIC_CF_CLIENT_ID,
  NEXT_PUBLIC_CF_CLIENT_SECRET: process.env.NEXT_PUBLIC_CF_CLIENT_SECRET,
};
