export const SET_COVERAGES = '@@MOBILIAR_COVERAGES/SET' as const;
export const SET_INITIAL_DAMAGES_ARRAY = '@@MOBILIAR_INITIAL_DAMAGES_ARRAY/SET' as const;
export const SET_DAMAGE_DETAIL = '@@MOBILIAR_DAMAGE_DETAIL/SET' as const;
export const FETCH_MOBILIAR_INSURANCE_SUM = '@@MOBILIAR_INSURANCE_SUM/FETCH' as const;
export const SET_INQUIRY_FIELDS = '@@MOBILIAR_INQUIRY_FIELDS/SET' as const;
export const SET_QUICK_ADJUST_FIELDS = '@@MOBILIAR_QUICK_ADJUST_FIELDS/SET' as const;
export const UPDATE_CHILDREN_DATES_OF_BIRTH_ARRAY_LENGTH =
  '@@MOBILIAR_CHILDREN_DATES_OF_BIRTH_ARRAY_LENGTH/UPDATE' as const;
export const SET_CHILD_DATE_OF_BIRTH = '@@MOBILIAR_CHILD_DATE_OF_BIRTH/SET' as const;
export const SET_MOBILIAR_FIELD = '@@MOBILIAR_FIELD/SET' as const;
export const SET_REFERRER_PAGE = '@@REFERRER_PAGE/SET' as const;
