export const interpolate = (str: string, replacementValues?: Record<string, string>) => {
  if (!str) {
    return '';
  }

  if (replacementValues) {
    const matches = str.match(/{{.*?}}/g);
    if (matches) {
      matches.forEach((match) => {
        const replacement = match.replace(/[{}]/g, '');
        str = str.replace(match, replacementValues[replacement]);
      });
    }
  }
  return str;
};
