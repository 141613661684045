import { asInt } from '../../utils';
import { RootState } from '../rootReducer';

type State = Pick<RootState, 'privateLiabilityInquiry'>;

export const getPrivateLiabilityFields = (rootState: State) => {
  return rootState.privateLiabilityInquiry;
};

export const getNumberOfChildren = (state: State) => {
  const childrenBirthDates = getPrivateLiabilityFields(state).childrenDatesOfBirth;
  if (childrenBirthDates) {
    return childrenBirthDates.length;
  }
  return 0;
};

export const getNumberOfAdultResidents = (state: State) => {
  return asInt(getPrivateLiabilityFields(state).numberOfInsurees) - getNumberOfChildren(state) - 1;
};
