import { QuoteDto } from '../../../types/Responses';
import { ActionType } from './selectedPremiums.actionCreators';
import * as actions from './selectedPremiums.actionTypes';

export type SelectedPremiumsState = readonly QuoteDto[];

export const initialState: SelectedPremiumsState = [];

const selectedPremiumsReducer = (
  state: SelectedPremiumsState = initialState,
  action: ActionType,
): SelectedPremiumsState => {
  switch (action.type) {
    case actions.SET_SELECTED_PREMIUMS:
      return [...action.premiums];
    case actions.ADD_SELECTED_PREMIUM:
      return [...state, action.premium];
    case actions.REMOVE_SELECTED_PREMIUM:
      return state.filter((premium) => premium.productId !== action.premiumId);
    case actions.CLEAR_SELECTED_PREMIUMS:
      return [];
    default:
      return state;
  }
};

export default selectedPremiumsReducer;
