import { Field } from '../../enums';
import { ActionType } from './privateLiabilityinquiry.actionCreators';
import * as actions from './privateLiabilityinquiry.actionTypes';
import { PrivateLiabilityFields } from './types';

export type PrivateLiabilityInquiryState = Readonly<PrivateLiabilityFields>;

export const initialState: PrivateLiabilityInquiryState = {
  [Field.PossessionType]: null,
  [Field.HouseholdType]: null,
  [Field.GrossNegligence]: null,
  [Field.NonOwnerDriverInsurance]: null,
  [Field.DateOfBirth]: null,
  [Field.IsDogOwner]: null,
  [Field.NumberOfDogs]: null,
  [Field.NumberOfInsurees]: null,
  [Field.NumberOfChildren]: null,
  [Field.ChildrenDatesOfBirth]: [],
  [Field.City]: { postalCode: null, name: null, canton: null },
  [Field.InsuranceStartDate]: null,
  [Field.CompensationForPrivateLiability]: null,
  [Field.ContractTerminatedByPreviousInsurer]: null,
  [Field.NumberOfPrivateLiabilityCompensations]: null,
  [Field.PrivateLiabilityDamages]: [],
  [Field.PreviousInsurer]: null,
  [Field.ReasonForCancellation]: null,
  [Field.EmailAddress]: null,
  [Field.DataProtectionAccepted]: true,
};

const privateLiabilityInquiryReducer = (
  state: PrivateLiabilityInquiryState = initialState,
  action: ActionType,
): PrivateLiabilityInquiryState => {
  switch (action.type) {
    case actions.SET_FIELD:
      return { ...state, [action.field]: action.newValue };
    case actions.SET_PRIVATE_LIABILITY_INQUIRY_FIELDS:
      return { ...action.fields };
    case actions.SET_ARRAY_FIELD:
      const newArray = [...state[action.field]];
      newArray[action.index] = action.newValue;
      return { ...state, [action.field]: newArray };
    default:
      return state;
  }
};

export default privateLiabilityInquiryReducer;
