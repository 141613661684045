import { range } from '@scout24ch/fs24-utils';
import { useTranslations } from './useTranslations';

export const useNOrMore = (num: number) => {
  const t = useTranslations();

  return range(1, num, String)
    .map((n) => ({ value: n, label: n }))
    .concat({ value: String(num), label: t('orMore', { num: String(num) }) });
};
