/**
 * Component was generated by svgr (https://github.com/smooth-code/svgr)
 * Read our README.md for details
 */

/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const MobiLogoFr = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--mobi-logo_fr ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 144 22"
    {...props}
  >
    <title>{'mobiliar-fr copy'}</title>
    <desc>{'Created with Sketch.'}</desc>
    <g id="mobiliar-fr-copy" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M58,22 C52.8326489,22 51,17.7003889 51,14.0183592 C51,8.10569261 54.6149897,6 58,6 C61.3839836,6 65,8.10569261 65,14.0183592 C65,19.903487 61.3839836,22 58,22 M58,9.90029961 C56.4276181,9.90029961 55.5616016,11.3593421 55.5616016,14.0183592 C55.5616016,16.6508574 56.4276181,18.0997004 58,18.0997004 C59.5959959,18.0997004 60.4373717,16.6875757 60.4373717,14.0183592 C60.4373717,11.3216039 59.5959959,9.90029961 58,9.90029961"
        id="mobi-f-Fill-1"
        fill="#DB3642"
      />
      <path
        d="M73.6102774,22 C70.4046157,22 68.7778915,20.8421851 68.0832575,20.3490137 L68,20.2898331 L68,0.602427921 L72.2885023,0 L72.2885023,7.61001517 C72.9171214,6.94941831 74.0452857,6.13050076 75.795172,6.13050076 C79.1023192,6.13050076 81,8.75467881 81,13.3363682 C81,19.0839656 78.5126194,22 73.6102774,22 M72.2885023,17.8593829 C72.6003486,18.047041 73.0397908,18.2200303 73.8413294,18.2200303 C75.0340306,18.2200303 76.6208504,17.7713708 76.6208504,13.8745574 C76.6208504,11.262519 75.8454222,9.99898837 74.2512127,9.99898837 C73.5092845,9.99898837 72.8515992,10.3181588 72.2885023,10.9514416 L72.2885023,17.8593829"
        id="mobi-f-Fill-2"
        fill="#DB3642"
      />
      <path
        d="M83.0506358,22 L87.9504895,22 L87.9504895,6.8676322 L83.0506358,6.8676322 L83.0506358,22 Z M85.5002813,5.48736948 C83.9361989,5.48736948 83,4.66891209 83,3.29824857 L83,3.161839 C83,1.78713372 83.9108811,1 85.5002813,1 C87.0868685,1 88,1.78713372 88,3.161839 L88,3.29824857 C88,4.66891209 87.0638011,5.48736948 85.5002813,5.48736948 L85.5002813,5.48736948 Z"
        id="mobi-f-Fill-3"
        fill="#DB3642"
      />
      <polyline id="mobi-f-Fill-4" fill="#DB3642" points="91 22 95 22 95 0 91 0.625838573 91 22" />
      <path
        d="M98.051761,22 L102.950489,22 L102.950489,6.8676322 L98.051761,6.8676322 L98.051761,22 Z M100.500281,5.48736948 C98.9361989,5.48736948 98,4.66891209 98,3.29824857 L98,3.161839 C98,1.78713372 98.9120063,1 100.500281,1 C102.086868,1 103,1.78713372 103,3.161839 L103,3.29824857 C103,4.66891209 102.066052,5.48736948 100.500281,5.48736948 L100.500281,5.48736948 Z"
        id="mobi-f-Fill-5"
        fill="#DB3642"
      />
      <polyline
        id="mobi-f-Fill-6"
        fill="#DB3642"
        points="43.0636816 1 38.0149254 14.7778874 32.9930348 1 28.3452736 1 27 22 31.3616915 22 32.0233831 10.6086026 36.3507463 22 39.6487562 22 43.9457711 10.6086026 44.6074627 22 49 22 47.6547264 1 43.0636816 1"
      />
      <path
        d="M136.947477,9.75184017 C138.356125,9.75184017 139.233172,10.5216837 139.371447,12.3723672 L134.28409,12.3733869 C134.510314,10.445719 135.536385,9.75184017 136.947477,9.75184017 Z M140.675045,17.3865469 C139.864938,17.9626549 138.738703,18.2552975 137.674521,18.2552975 C135.702022,18.2552975 134.481486,17.4808654 134.304123,15.2743205 L142.971001,15.2743205 C142.971001,15.2743205 142.990057,14.0619444 142.997874,13.657649 C143.083869,8.57464232 140.552894,6 136.84829,6 C132.472831,6 130,9.75285983 130,13.777969 C130,18.5576267 132.519737,22 137.253832,22 C140.132205,22 141.818381,20.8957079 142.704711,20.0045247 L140.813321,17.2621483 L140.675045,17.3865469 L140.675045,17.3865469 Z"
        id="mobi-f-Fill-8"
        fill="#DB3642"
      />
      <path
        d="M125.507766,22 L121,22 L121,7.29169122 L124.658191,7.29169122 L125.294466,8.73099588 C125.875863,7.76998625 126.895766,7 129.255005,7 L130,7 L129.431029,10.6387743 C129.431029,10.6387743 128.883801,10.5931055 128.502761,10.5931055 C127.161873,10.5931055 126.159572,11.2987625 125.507766,12.3894127 L125.507766,22"
        id="mobi-f-Fill-10"
        fill="#DB3642"
      />
      <polyline
        id="mobi-f--Fill-11"
        fill="#DB3642"
        points="115 5 112.020273 5 107 1.93719461 107 1 111.613062 1 115 5"
      />
      <path
        d="M112.946752,9.75184017 C114.35633,9.75184017 115.234323,10.5216837 115.371617,12.3723672 L110.283456,12.3733869 C110.511627,10.445719 111.53522,9.75184017 112.946752,9.75184017 Z M116.675171,17.3865469 C115.865091,17.9626549 114.739872,18.2552975 113.674749,18.2552975 C111.70134,18.2552975 110.481823,17.4808654 110.304465,15.2743205 L118.971048,15.2743205 C118.971048,15.2743205 118.991081,14.0619444 118.997921,13.657649 C119.082935,8.57464232 116.553024,6 112.848546,6 C108.474212,6 106,9.75285983 106,13.777969 C106,18.5576267 108.52014,22 113.254074,22 C116.13186,22 117.819445,20.8957079 118.704768,20.0045247 L116.812464,17.2621483 L116.675171,17.3865469 L116.675171,17.3865469 Z"
        id="mobi-f-Fill-12"
        fill="#DB3642"
      />
      <path
        d="M2.63106886,19.1892832 L4.6972494,0.143455282 L4.6330995,0.12684467 C4.40234447,0.063925687 3.74700018,0 3.44424958,0 C2.20463356,0 1.22115562,0.393117807 0.595809489,0.739423891 C0.318903452,1.04244171 0.0595347979,2.05367561 0.0595347979,2.38437779 L0.0595347979,2.47447777 L2.10310135,2.22682865 L0,19.6146155 C0,20.1859199 0.474893853,21.2872538 1.01670666,21.9713089 L1.03932066,22 L1.0734724,22 C1.92449695,22 3.5873177,21.6486604 4.49510799,21.2781934 L4.51956803,21.2686297 L4.5320288,21.2449722 C4.76832195,20.8271902 5,19.9422976 5,19.4585764 L5,19.3689798 L2.74552335,19.6216624 C2.6924497,19.4812273 2.63199188,19.2431418 2.63106886,19.1892832"
        id="mobi-f-Fill-14"
        fill="#DB3642"
      />
      <path
        d="M12.4772569,19.6983169 C10.7874867,19.6983169 9.89462303,18.4016513 9.89462303,15.9475389 C9.89462303,14.171229 10.312319,11.688155 11.2477733,10.1460781 C12.140637,8.69647507 13.5604955,7.83016831 15.0470623,7.83016831 C15.7705897,7.83016831 16.537221,7.99885678 17.0205989,8.26255954 L15.7356962,17.4489679 C14.9998534,18.8152429 13.7200821,19.6983169 12.4772569,19.6983169 Z M18.497416,19.5992379 C18.4373786,19.4188631 18.3665653,19.0972372 18.365539,19.0377898 L19.8957226,7.45773261 L19.8546714,7.42978723 C19.0921453,6.89628453 17.4562548,6 15.078877,6 C12.0585346,6 10.1650478,7.70974913 9.11003189,9.14563353 C7.7686838,10.99765 7,13.6031756 7,16.2935535 C7,21.2576691 9.61701426,22 11.1764469,22 C12.5573067,22 14.4646483,21.3877421 15.6925925,19.6749444 L15.7392882,19.6749444 C15.8747572,20.4172753 16.2154822,21.3648777 16.5613386,21.958336 L16.5864824,22 L16.6336913,22 C17.4993586,22 19.4657112,21.6565259 20.4411905,21.2703715 L20.4658212,21.2617339 L20.4812154,21.2378533 C20.7377854,20.8227374 21,19.9289933 21,19.4661162 L21,19.3771991 L18.497416,19.5992379 L18.497416,19.5992379 Z"
        id="mobi-f-Fill-16"
        fill="#DB3642"
      />
    </g>
  </svg>
);

MobiLogoFr.displayName = 'MobiLogoFr';

export default MobiLogoFr;
