import { AppState, Auth0Provider } from '@scout24ch/fs24-auth-react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { configureScope } from 'utils/sentry';
import { useUser } from '../hooks';

const { publicRuntimeConfig } = getConfig();

export interface AuthProps {
  children?: React.ReactNode;
}

function SentryUser(): null {
  const { user } = useUser();

  const id = user?.sub;

  useEffect(() => {
    configureScope((scope) => {
      scope.setUser(id ? { id } : null);
    });
  }, [id]);

  return null;
}

function getRedirectURI(): string {
  if (typeof window === 'undefined') {
    return '';
  }

  return `${window.location.origin}/auth/callback`;
}

export function AuthProvider(props: AuthProps): JSX.Element {
  const { children } = props;
  const router = useRouter();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      if (appState?.returnTo) {
        router.replace(appState.returnTo);

        return;
      }

      router.replace('/');
    },
    [router],
  );

  return (
    <Auth0Provider
      domain={publicRuntimeConfig.NEXT_PUBLIC_AUTH0_DOMAIN!}
      clientId={publicRuntimeConfig.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: publicRuntimeConfig.NEXT_PUBLIC_AUTH0_AUDIENCE,
        scope: publicRuntimeConfig.NEXT_PUBLIC_AUTH0_SCOPE,
        redirect_uri: getRedirectURI(),
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <SentryUser />
      {children}
    </Auth0Provider>
  );
}
