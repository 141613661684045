export enum Size {
  One = 'one',
  OneAndHalf = 'oneAndHalf',
  Two = 'two',
  TwoAndHalf = 'twoAndHalf',
  Three = 'three',
  ThreeAndHalf = 'threeAndHalf',
  Four = 'four',
  FourAndHalf = 'fourAndHalf',
  Five = 'five',
  FiveAndHalf = 'fiveAndHalf',
  Six = 'six',
  SixAndHalf = 'sixAndHalf',
  Seven = 'seven',
  SevenAndHalf = 'sevenAndHalf',
  Eight = 'eight',
  EightAndHalf = 'eightAndHalf',
  Nine = 'nine',
}
