import { ProductType } from '../enums';
import { getHouseholdInquiryFields } from '../state/inquiry/inquiry.selectors';
import { getMobiliarInquiryFields } from '../state/mobiliar/inquiry/inquiry.selectors';
import { getPrivateLiabilityFields } from '../state/privateLiabilityInquiry/privateLiability.selectors';
import { useSelector } from '../state/rootReducer';
import { useProductType } from './useProductType';

export const useGetInquiryFields = () => {
  const productType = useProductType();
  const householdFields = useSelector(getHouseholdInquiryFields);
  const mobiliarFields = useSelector(getMobiliarInquiryFields);
  const privateLiabilityFields = useSelector(getPrivateLiabilityFields);

  if (productType === ProductType.Household) {
    return householdFields;
  }

  if (productType === ProductType.Mobiliar) {
    return mobiliarFields;
  }

  return privateLiabilityFields;
};
