export enum Offer {
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  StreetName = 'streetName',
  StreetNumber = 'streetNumber',
  PostalCode = 'postalCode',
  City = 'city',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Nationality = 'nationality',
  Language = 'language',
  CompanyTermsAccepted = 'companyTermsAccepted',
  Gender = 'gender',
  ResidencePermit = 'residencePermit',
  ResidencePermitSince = 'residencePermitSince',
  HouseholdMembers = 'householdMembers',
  isDirectDeal = 'isDirectDeal',
}
