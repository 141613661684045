import { DesignSystemProvider } from '@scout24ch/fs24-design-system';
import {
  configureWidgets,
  WidgetDataProvider,
  widgetManager,
  WidgetManagerConfig,
  WidgetManagerFactory,
  WidgetNamespaces,
} from '@scout24ch/fs24-widgets';
import { CookieConsentProvider } from '@scout24ch/fs24-cmp';
import { initSplitClient } from '@scout24ch/fs24-split';
import withRedux from 'next-redux-wrapper';
import App, { AppContext } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { fetchTranslations } from '../src/api/translations';
import { isProd } from '../src/common/environment';
import { ErrorBoundary } from '../src/components/ErrorBoundary';
import { GoogleTagManager } from '../src/components/GoogleTagManager';
import { HouseholdPageLayout } from '../src/components/Layouts';
import { CMS_API } from '../src/config';
import { LanguageProvider } from '../src/context/language';
import { Language } from '../src/enums';
import { setLanguage, updateLanguage } from '../src/state/language/language.actionCreators';
import { initStore } from '../src/state/rootState';
import '@scout24ch/fs24-ui/styles.css';
import '../src/styles/reset.scss';
import '../src/styles/common.scss';
import '../src/styles/componentStyles.scss';
import { ConfiguredNextContext } from '../src/types/next';
import { captureException, init as initSentry } from '../src/utils/sentry';
import { AuthProvider } from 'context/AuthProvider';

const loadSmoothScrollPolyfill = async () => {
  if (!('scrollBehavior' in document.documentElement.style)) {
    await import('scroll-behavior-polyfill');
  }
};

initSentry();
initSplitClient();

if (typeof window !== 'undefined') {
  loadSmoothScrollPolyfill();
}

configureWidgets({
  apiBaseURL: CMS_API,
});

interface HouseholdAppProps {
  pageProps: Record<string, never>;
  store: ReturnType<typeof initStore>;
  languageCode: Language;
  widgetData: WidgetManagerFactory;
}

class HouseholdApp extends App<HouseholdAppProps> {
  public static displayName = 'HouseholdApp';

  public static async getInitialProps({ Component, ctx }: AppContext & { ctx: ConfiguredNextContext }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const store: HouseholdAppProps['store'] = ctx.store;
    const languageCode = this.getLanguage(ctx);
    const reduxLanguageCode = store.getState().language.languageCode;

    if (languageCode !== reduxLanguageCode) {
      store.dispatch(updateLanguage(languageCode));
    }

    const [translations, widgetData] = await Promise.all([
      fetchTranslations(languageCode),
      this.getWidgetData(languageCode),
    ]);

    store.dispatch(setLanguage(languageCode, translations));

    return { pageProps, languageCode, widgetData };
  }

  private static getLanguage(ctx: ConfiguredNextContext): Language {
    const { locale = 'de' } = ctx;

    return locale as Language;
  }

  private static getWidgetData(locale: string): Promise<WidgetManagerFactory> {
    const requiredNamespaces = [WidgetNamespaces.FOOTER, WidgetNamespaces.HEADER];

    // temporary solution because CMS doesn't support English language
    const language = locale === 'en' ? 'de' : locale;
    const options: WidgetManagerConfig = {
      locale: language,
      onAPIError: captureException,
    };

    return widgetManager.fetchData(requiredNamespaces, options);
  }

  public render() {
    const { Component, pageProps, languageCode, widgetData, store } = this.props;

    return (
      <LanguageProvider value={languageCode || 'de'}>
        <Head>
          <meta key="charset" charSet="utf-8" />
          {isProd && <meta name="google" content="notranslate" />}
          <title key="partial-head-title">FinanceScout24</title>
          <meta name="author" content="FinanceScout24" />
          <meta name="copyright" content="FinanceScout24" />
          <meta name="description" content="Description of FinanceScout24" />
          <meta name="robots" content="noindex" />
          <meta name="keywords" content="FinanceScout,FinanceScout24,finance,household,private household,household" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
        </Head>
        <ErrorBoundary>
          <StoreProvider store={store}>
            <CookieConsentProvider>
              <DesignSystemProvider locale={languageCode || 'de'}>
                <AuthProvider>
                  <GoogleTagManager>
                    <WidgetDataProvider value={widgetData}>
                      <HouseholdPageLayout>
                        <Component {...pageProps} />
                      </HouseholdPageLayout>
                    </WidgetDataProvider>
                  </GoogleTagManager>
                </AuthProvider>
              </DesignSystemProvider>
            </CookieConsentProvider>
          </StoreProvider>
        </ErrorBoundary>
      </LanguageProvider>
    );
  }
}

export default withRedux(initStore)(HouseholdApp);
