import { Language } from '../../enums';
import { Translations } from '../../types';
import { updateLanguagePath } from '../../utils/clientNavigation';
import { ActionType } from './language.actionCreators';
import { SET_LANGUAGE, UPDATE_LANGUAGE } from './language.actionTypes';

export interface InternalLanguageState {
  languageCode: Language;
  translations: Translations;
}

export type LanguageState = Readonly<InternalLanguageState>;

const initialState: LanguageState = {
  languageCode: Language.De,
  translations: {} as Translations,
};

const languageReducer = (state: LanguageState = initialState, action: ActionType): LanguageState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        languageCode: action.languageCode,
        translations: action.translations,
      } as LanguageState;
    case UPDATE_LANGUAGE:
      updateLanguagePath(state.languageCode, action.languageCode);
      return { ...state, languageCode: action.languageCode } as LanguageState;

    default:
      return state;
  }
};

export default languageReducer;
